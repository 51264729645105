import {OmnibarSearchTypeEnum} from "../../../core/enum/omnibar-search-type-enum";

export class SearchParameters{
  searchType: OmnibarSearchTypeEnum;
  lro: string;
  page: number;
  noTracking: boolean;

  constructor(lro: string, page: number) {
    this.lro = lro;
    this.page = page;
  }
}

export class WildcardSearchParameters extends SearchParameters{
  searchText: string;
  isAutoSuggestOwnerSearch: boolean;
  constructor(lro: string, page: number) {
    super(lro, page);
    this.searchType = OmnibarSearchTypeEnum.ALL_VALUE;
    this.isAutoSuggestOwnerSearch = false;
  }
}

export class OwnerSearchParameters extends SearchParameters{
  nextItemOwner: number;
  constructor(lro: string, page: number) {
    super(lro, page);
    this.searchType = OmnibarSearchTypeEnum.OWNER_VALUE;
  }
  firstName: string;
  lastOrCorpName: string;
}

export class OwnerFullNameSearchParameters extends SearchParameters{
  constructor(lro: string, page: number) {
    super(lro, page);
    this.searchType = OmnibarSearchTypeEnum.OWNER_VALUE;
  }
  ownerFullName: string;
}

export class InstrumentSearchParameters extends SearchParameters {
  constructor(lro: string, page: number) {
    super(lro, page);
    this.searchType = OmnibarSearchTypeEnum.INSTRUMENT_VALUE;
  }
  instrument: string;
}

export class LotSearchParameters extends SearchParameters {
  constructor(lro: string, page: number) {
    super(lro, page);
    this.searchType = OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE;
  }
  lot: string;
  lotId: number
}

export class CondoSearchParameters {
  constructor(pin: string, condoAddress : string) {
    this.pin = pin;
    this.condoAddress = condoAddress;
  }
  pin: string;
  condoAddress : string
}
