import * as _ from 'lodash';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation, effect, inject, input } from '@angular/core';
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { BaseUnsubscribe } from '../../base-unsubscribe/base-unsubscribe';
import { BannerMessage } from '../../../../core/model/message-center/banner-message';
import { UserService } from '../../../../shared/service/user.service';
import { MessageCenterService } from '../../../../shared/service/message-center.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { Message } from '../../../../core/model/message-center/message';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'gema3g-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrl: './user-messages.component.scss',
  encapsulation: ViewEncapsulation.None  //set to None for us to apply styling to the html content (within the message html content) that is outside of this component
})
export class UserMessagesComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {
  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  private loggerService: LoggerService = inject(LoggerService);
  private router: Router = inject(Router);
  private userService: UserService = inject(UserService);
  private messageCenterService: MessageCenterService = inject(MessageCenterService);

  private bannerMessages: BannerMessage | null = null;
  private expiredBannerMessages: BannerMessage | null = null;
  private allBannerMessages: Message[] | null;

  @ViewChild('bannerMessageSideNavMessageContainer') messageSideBar: MatSidenav;
  sort: MatSort;
  dataSource = new MatTableDataSource<Message>();
  isLoading: boolean = this.messageCenterService.isBannerMessagesLoading();
  displayedColumns: string[];
  hasBannerMessages: boolean = false;
  faCircleXmark = faCircleXmark;
  isOverlapDisplay: boolean = true;
  messageSubject: string;
  messageStatus: string;
  safeHtml: SafeHtml;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSort();
  }

  setDataSort = () => {
    this.dataSource.sort = this.sort;

    if (this.sort) {
      this.sort.sort({
        id: 'createDate',
        start: 'desc',
        disableClear: true
      });
      this.sort.disableClear = true;
    }

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'createDate':
          return new Date(item.getCreateDate()).getTime();
        case 'expiryDate':
          return new Date(item.getExpiryDate()).getTime();
        default: // @ts-ignore
          return item[property];
      }
    }
  }
  
  populateTable = () => {
    this.messageCenterService.showBannerMessagesLoading();
    this.hasBannerMessages = false;

    try {
      if (!_.isEmpty(this.allBannerMessages)) {
        this.loggerService.logDebug(`${this.allBannerMessages?.length} banner messages found for beid ${this.userService.getBEID()}`);

        this.displayedColumns = [];
        this.displayedColumns.push('messageId');        
        this.displayedColumns.push('createDate');
        this.displayedColumns.push('status');
        this.displayedColumns.push('subject');
        this.displayedColumns.push('expiryDate');

        this.hasBannerMessages = true;
        this.dataSource = new MatTableDataSource(this.allBannerMessages!);

      } else {
        this.loggerService.logDebug(`no banner messages to display for beid ${this.userService.getBEID()}`);
      }
    } catch (e) {
      this.loggerService.logError(`error displaying banner messages for beid ${this.userService.getBEID()}`, e);

    } finally {
      this.messageCenterService.hideBannerMessagesLoading();
    }
  }

  mergeBannerMessages = () => {
    if (this.bannerMessages?.hasMessages() && this.expiredBannerMessages?.hasMessages()) {
      this.allBannerMessages = this.bannerMessages?.getMessages().concat(this.expiredBannerMessages?.getMessages());
      this.loggerService.logDebug(`both active and expired messages found for beid ${this.userService.getBEID()}`);
    } else if (this.bannerMessages?.hasMessages()) {
      this.allBannerMessages = this.bannerMessages.getMessages();
      this.loggerService.logDebug(`only active messages found for beid ${this.userService.getBEID()}`);
    } else if (this.expiredBannerMessages?.hasMessages()) {
      this.allBannerMessages = this.expiredBannerMessages.getMessages();
      this.loggerService.logDebug(`only expired messages found for beid ${this.userService.getBEID()}`);
    }

    this.populateTable();
  }

  get bannerMessagesCount() {
    if (!_.isEmpty(this.allBannerMessages)) {
      return this.allBannerMessages?.length;
    } else {
      return 0;
    }
  }

  get priorityMessagesCount() {
    return 0;
  }
  
  toggleMessageDisplay = (event: MatSlideToggleChange) => {
    let overlapMode = event.checked;

    if (overlapMode) {
      this.messageSideBar.mode = 'over';
    } else {
      this.messageSideBar.mode = 'side';
    }
  }
  
  closeMessages = () => {
    this.router.navigate(['../home']);
  }

  closeMessage = () => {
    this.messageSideBar.opened = false;
  }

  openBannerMessage = (message: Message) => {

    this.messageSubject = message.getSubject();
    this.messageStatus = message.getStatus();

    this.safeHtml = "";
    let rawHtml: string = "";

    try {
      let messageLines: string[] = _.split(message.getBody(), '^');
      messageLines.map(line => {
        if (!_.isEmpty(line)) {
          line = line.trim();
          rawHtml = rawHtml + "<div class='message-line'>" + line + "</div>";
        }
      });

      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(_.unescape(rawHtml));

    } catch (e) {
      this.safeHtml = 'Error displaying the message detail';
      this.loggerService.logDebug(`error displaying the banner message detail`, e);
    }

    this.messageSideBar.opened = true;
  }

  ngOnInit(): void {
    this.messageCenterService.bannerMessages$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((bannerMessages) => {
        if (bannerMessages && bannerMessages.hasMessages()) {
          this.bannerMessages = bannerMessages;
          this.mergeBannerMessages();
        } 
      });

    this.messageCenterService.expiredBannerMessages$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((bannerMessages) => {
        if (bannerMessages && bannerMessages.hasMessages()) {
          this.expiredBannerMessages = bannerMessages;
          this.mergeBannerMessages();
        } 
      });

    this.messageCenterService.getMessageDetailObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((messageDetailToDisplay) => {
        if (!_.isEmpty(messageDetailToDisplay)) {
          this.openBannerMessage(messageDetailToDisplay);
        } 
      });
  }

  ngAfterViewInit(): void {
    setTimeout(
      async () => {
        this.bannerMessages = await lastValueFrom(this.messageCenterService.getBannerMessages(), {defaultValue: new BannerMessage()});
        this.messageCenterService.setBannerMessages(this.bannerMessages);
        this.expiredBannerMessages = await lastValueFrom(this.messageCenterService.getExpiredBannerMessages(), {defaultValue: new BannerMessage()});
        this.messageCenterService.setExpiredBannerMessages(this.expiredBannerMessages);
      }
    );
  }

}
