import {EStoreProductDescription} from "./e-store-product-description";
import {EStoreProductPriceDescription} from "./e-store-product-price-description";
import {EStoreProductCta} from "./e-store-product-cta";
import {EstoreProductCategoryEnum} from "../../../enum/estore-product-category-enum";
import {BaseModel} from "../../base-model";
import _ from "lodash";
import {TITLE_CHECK_PROD_ID, TILE_INSTRUMENT_IMAGE_PROD_ID, TILE_PLAN_IMAGE_PROD_ID} from "../../../../shared/constant/constants";
import {DataService} from "../../../../shared/service/data.service";


export class EStoreProduct extends BaseModel {

  constructor(product?: EStoreProduct, index?: number) {
    super(product);
    if (_.isNumber(index)) {
      this.index = index;
    } else {
      this.index = Math.floor((Math.random() * 1000) + 1)
    }
    if(product){
      this.description = new EStoreProductDescription(product.description);
      this.pricedesc = new EStoreProductPriceDescription(product.pricedesc);
      this.cta = new EStoreProductCta(product.cta);

      if (product.prodId == TITLE_CHECK_PROD_ID) {
        this.description.addTextToBullets('Yes', true);
      }
      if (product.licensePackageId > 0) {
        this.description.addTextToBullets('', true);
      }
    }
  }

  title: string;
  prodId: string;
  category: EstoreProductCategoryEnum;
  display: string;
  image: string;
  samplelink: string;
  description: EStoreProductDescription;
  pricedesc: EStoreProductPriceDescription;
  cta: EStoreProductCta;
  balance: number;
  mpacrpttype: string;
  licenseCount: number;
  index: number; // some products do not have a prodId use this as a unique number for UI
  licensePackageId: number;

  get price(): number {
    // some products have no price description so assigning them default price as 0.00
    return (this.balance && this.balance > 0) ?
      0.00 :
      ((this.pricedesc && this.pricedesc.price) ?
        parseFloat(this.pricedesc.price.replace('$', ''))
        : 0.00);
  }

  get gema3Image(): string {
    return "/assets/" + this.image;
  }

  override toString(): string {
    return `Title=${this.title}; ProdId=${this.prodId}`;
  }

  get hasRibbons(): boolean {
    return this.category == EstoreProductCategoryEnum.MPAC_BUNDLE || this.prodId == TITLE_CHECK_PROD_ID;
  }

  get ribbonText(): string {
    if (this.category == EstoreProductCategoryEnum.MPAC_BUNDLE) {
      return 'BEST VALUE';
    } else if (this.prodId == TITLE_CHECK_PROD_ID) {
      return 'New';
    }
    return '';
  }

  get action(): string {
    if (this.category == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION) {
      return DataService.TILE_PURCHASE_BUTTON_CAPTION;
    } else if (this.prodId === TILE_PLAN_IMAGE_PROD_ID) {
      return DataService.TILE_PROD_133_BUTTON_CAPTION;
    } else if (this.prodId === TILE_INSTRUMENT_IMAGE_PROD_ID) {
      return DataService.TILE_PROD_134_BUTTON_CAPTION;
    } else if (this.isProductComparableSearchTopUp() || this.isProductStandardTopUp()) {
      return DataService.TILE_REPORT_TOP_UPS_BUTTON_CAPTION;
    } else {
      return DataService.TILE_DEFAULT_BUTTON_CAPTION;
    }
  }

  isProductStandardTopUp = () : boolean => {
      return ['1250', '1251', '1252', '1265', '1266', '1267']
        .includes(this.prodId);
  }

  isProductComparableSearchTopUp = () : boolean => {
    return ['1260', '1261', '1262' ,'1263', '1264','1268', '1269', '1270' ,'1271', '1272']
      .includes(this.prodId);
  }

  get hasLicensePackageId(): boolean {
    return  ( !!this.licensePackageId && this.licensePackageId > 0);
  }

  get hasSample(): boolean {
    return !!this.samplelink;
  }

  get hasPdfSample(): boolean {
    return this.hasSample && this.samplelink.endsWith('.pdf');
  }

  get hasUrlSample(): boolean {
    return ! this.hasPdfSample;
  }

  get titleCheckProdId(): string {
  return TITLE_CHECK_PROD_ID;
  }

}
