@if (product?.title) {
  <div class="catalogue-product-container">
    <mat-card-header>
      @if (product.hasRibbons) {
        <div class="cp-special-offer-ribbon">
          <span id="cp-ribbon-label-{{product.prodId}}">{{ product.ribbonText }}</span>
        </div>
      }
    </mat-card-header>

    <mat-card-content>
      <div class="block sm:flex flex-row min-h-[180px]">
        <div class="flex flex-col w-1/3">
          <img id="cp-img-{{product.prodId}}" [ngClass]="imageClass()" [src]="product.gema3Image">
          @if (product.category === 'catGwhRSubspn' && product.licensePackageId > 0 && product.pricedesc.desc) {
            <div class="cp-middle-ribbon text-xxs">
              {{ product.pricedesc.desc }}
            </div>
          }
          @if (product.samplelink?.trim() && !product.hasLicensePackageId) {
            @if(product.hasPdfSample) {
              <a
                (click)="downloadSample(product.samplelink)"
                class="anchor text-[9px] md:text-[10px] lg:text-[12px]"
                id="cp-link-sample-{{product.prodId}}">
                Download Sample
              </a>
            } @else {
              <a
                href="{{product.samplelink}}"
                target="_blank"
                class="anchor text-[9px] md:text-[10px] lg:text-[12px]"
                id="cp-link-sample-{{product.prodId}}">
                View Sample
              </a>
            }
          }
        </div>
        <div class="pl-1 w-2/3">
          <div id="cp-title-label-{{product.prodId}}" class="text-os-bold mr-1.5" [ngClass]="product.licensePackageId > 0 ? 'text-os-bold cp-green' : 'text-os-bold'" [innerHTML]="product.title"></div>
          @if (product.description) {
            <div id="cp-desc-start-{{product.prodId}}" [innerHtml]="product.description.starthtml" class="cp-start-html"></div>
            @if (product.description.bullets.length > 0) {
              <ul [ngClass]="{'ul-after-text':product.description.starthtml}">
                @for (bullet of product.description.bullets; track bullet; let j = $index) {
                  <li class="cp-bullet"><span id="cp-desc-bullet-{{j}}-{{product.prodId}}" [innerHtml]="bullet" [ngClass]="product.prodId === product.titleCheckProdId ? 'cp-desc-bullet-title' : 'cp-desc-bullet'"></span></li>
                }
              </ul>
            }
          }
          <div id="cp-desc-end-{{product.prodId}}" [innerHtml]="product?.description?.endhtml" class="cp-end-html"></div>
          @if (product.category == 'catGwhRSubspn') {
            @if (!product.hasLicensePackageId) {
              <div class="cp-start-html"><br>
                <span id="cp-sed-{{product.prodId}}">Subscription Expiration Date : {{ GWHsubspnEndDate | date:'MMM d, y' }} </span>
                @if (earlyRenewalPrice) {
                  <span id="cp-erp-{{product.prodId}}"><br>Early Renew Price : {{ earlyRenewalPrice }}</span>
                }
                <br>
                <span id="cp-rp-{{product.prodId}}" class="mt-2">Renew Price : ${{ renewalPrice }}</span>
              </div>
            } @else if (product.samplelink) {
              <span>
                  <a id="cp-lp-sl" href="{{product.samplelink}}" target="_blank" class="anchor text-[9px] md:text-[10px] lg:text-[12px]">Subscription Details</a>
              </span>
            }
          }
        </div>
      </div>
      @if (product.category === 'catGwhRSubspn' && product.licensePackageId > 0 && product.pricedesc.noOfPropertyReports) {
        <div class="mb-2">
          <span class="text-os-bold" id="cp-prod-pr-desc-nr-pr-rep">{{ product.pricedesc.noOfPropertyReports }}</span>
        </div>
      }
    </mat-card-content>

    <mat-card-actions align="end" class="mt-auto ml-auto">
      <div class="block sm:flex items-center">
        <div class="text-left pr-5">
          @if (product.price > 0 && (product.licensePackageId ==null || !product.hasLicensePackageId)) {
            @if (product.pricedesc.desc) {
              <span id="cp-pricedesc-{{product.prodId}}-{{product.index}}">{{ product.pricedesc.desc }} {{ product.price | currency }}</span>
            } @else {
              <span id="cp-price-{{product.prodId}}-{{product.index}}">{{ product.price | currency }}</span>
            }
          }
        </div>
        <div class="get-dense-3 text-right">
          <button id="cp-btn-details-{{product.prodId}}-{{product.index}}"
                  (click)="buttonClicked()"
                  [ngClass]="getButtonColor()"
                  mat-raised-button
                  [disabled]="isButtonDisabled">
            {{ buttonCaption || product.action }}
          </button>
          @if (product.category == 'catGwhRSubspn') {
            <button class="button-pfi" id="cp-btn-pfi-{{product.prodId}}"
              (click)="getProFormaInvoice()"
              [ngClass]="getButtonColor()"
              mat-raised-button>
              Generate Pro Forma Invoice
            </button>
          }
        </div>
      </div>
    </mat-card-actions>
  </div>
}

@if (!product.title) {
  <div class="w-72"></div>
}
