import { Injectable, WritableSignal, signal, inject } from '@angular/core';
import { baseUrl } from "./system";
import * as _ from 'lodash';
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { LoggerService } from './log/logger.service';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { BannerMessage } from '../../core/model/message-center/banner-message';
import { PriorityMessage } from '../../core/model/message-center/priority-message';
import { MessageStatusEnum } from '../../core/enum/message-status.enum';
import { Message } from '../../core/model/message-center/message';

@Injectable({
  providedIn: 'root'
})

export class MessageCenterService {

  constructor() {
  }
  private userService = inject(UserService);
  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  private _bannerMessages = new BehaviorSubject<BannerMessage | null>(null);
  bannerMessages$ = this._bannerMessages.asObservable();

  private _expiredBannerMessages = new BehaviorSubject<BannerMessage | null>(null);
  expiredBannerMessages$ = this._expiredBannerMessages.asObservable();

  private bannerMessagesLoadingSignal: WritableSignal<boolean> = signal(false);
  private priorityMessagesLoadingSignal: WritableSignal<boolean> = signal(false);

  private _messageDetail = new BehaviorSubject<Message | undefined>(undefined);
  private messageDetail$ = this._messageDetail.asObservable();

  setBannerMessages = (bannerMessages: BannerMessage | null) => {
    this._bannerMessages.next(bannerMessages);
  }

  getBannerMessagesForDisplay = () => {
    return this._bannerMessages.getValue();
  }

  setExpiredBannerMessages = (bannerMessages: BannerMessage | null) => {
    if (!_.isEmpty(bannerMessages)) {
      bannerMessages.setMessagestoExpired();
    }
    this._expiredBannerMessages.next(bannerMessages);
  }

  getExpiredBannerMessagesForDisplay = () => {
    return this._expiredBannerMessages.getValue();
  }

  setMessageDetail = (message: Message | undefined) => {
    this._messageDetail.next(message);
  }

  getMessageDetailObservable = () => {
    return this.messageDetail$;
  }

  /**
   * Banner messages are displayed in the in-app ticker tape component
   */
  getBannerMessages(): Observable<BannerMessage> {
    let beId = this.userService.getBEID();
    let backendEndpoint = baseUrl + `/messagecenter/bannermessages/${beId}`;

    if (!beId) {
      return throwError(() => new Error(`missing beid when retrieving new banner messages`));
    }

    return this.https.get<BannerMessage>(backendEndpoint).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new BannerMessage(<BannerMessage>response);
        } else {
          return new BannerMessage();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving new banner messages for beid ${beId}`, err);
        return throwError(err);
      })
    );
  }

  getExpiredBannerMessages(): Observable<BannerMessage> {
    let beId = this.userService.getBEID();
    let backendEndpoint = baseUrl + `/messagecenter/expiredbannermessages/${beId}`;

    if (!beId) {
      return throwError(() => new Error(`missing beid when retrieving expired banner messages`));
    }

    return this.https.get<BannerMessage>(backendEndpoint).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new BannerMessage(<BannerMessage>response);
        } else {
          return new BannerMessage();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving expired banner messages for beid ${beId}`, err);
        return throwError(err);
      })
    );
  }

  showBannerMessagesLoading = () => {
    this.bannerMessagesLoadingSignal.set(true);
  }

  hideBannerMessagesLoading = () => {
    this.bannerMessagesLoadingSignal.set(false);
  }

  isBannerMessagesLoading = (): boolean => {
    return this.bannerMessagesLoadingSignal();
  }

  /**
   * Priority messages are re-purposed to display ad images in modal dialogs and can be triggered anywhere it is needed in the app,
   * as is the case with in-app popup ads displayed as part of the property report.
   */
  getPriorityMessages(): Observable<PriorityMessage> {
    let beId = this.userService.getBEID();
    let backendEndpoint = baseUrl + `/messagecenter/onboardingmessages/${beId}`;  //todo: make matching api changes in backend, ie, look for /onboardingmessages and duplicate both frontend and backend to /prioritymessages

    //todo: in messagecenterrepository, change findNewOnboardingMessagesByBEId(int businessEntityId); to findPriorityMessagesByBEId
    //todo: in messagecenterrepository, change findNewOnboardingMessagesByBEId query to native query like in findNewBannerMessagesByBEId
    if (!beId) {
      return throwError(() => new Error(`missing beid when retrieving new priority messages`));
    }

    return this.https.get<PriorityMessage>(backendEndpoint).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new PriorityMessage(<PriorityMessage>response);
        } else {
          return new PriorityMessage();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving new priority messages for beid ${beId}`, err);
        return throwError(err);
      })
    );
  }

  showPriorityMessagesLoading = () => {
    this.priorityMessagesLoadingSignal.set(true);
  }

  hidePriorityMessagesLoading = () => {
    this.priorityMessagesLoadingSignal.set(false);
  }

  isPriorityMessagesLoading = (): boolean => {
    return this.priorityMessagesLoadingSignal();
  }

  updateMessageStatus(messageId: number, messageStatus: MessageStatusEnum): Observable<boolean> {
    let beId = this.userService.getBEID();
    let status: string = messageStatus.toString()
    let backendEndpoint = baseUrl + `/messagecenter/updatemessagestatus/${beId}/${messageId}/${status}`;

    if (!beId) {
      return throwError(() => new Error(`missing beid when updating the message status`));
    }

    return this.https.get<boolean>(backendEndpoint).pipe(
      map(response => { //todo: check for boolean return type, if not throw the exception
        return response;
      }),
      catchError((err) => {
        this.loggerService.logError(`error updating the message status for beid ${beId}, message id ${messageId}, status ${status}`, err);
        return throwError(err);
      })
    );
  }
}
