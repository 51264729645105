import {BaseModel} from "../../base-model";
import {PdfReportPropertyInfoConfiguration} from "./item/pdf-report-property-info-configuration";
import {UserPreference} from "../../user/preference/user-preference";
import {PropertyDetail} from "../../property/property-detail";
import {UserAccessControl} from "../../user/user-access-control";
import {PdfReportAreaSaleConfiguration} from "./item/pdf-report-area-sale-configuration";
import {PdfReportMarketAnalyticsConfiguration} from "./item/pdf-report-market-analytics-configuration";
import {ComparableSalesResultPayload} from "../../comparables/comparable-sales-result-payload";

export class PdfReportConfigurationBase extends BaseModel {

  constructor(userReportPreferences: UserPreference | undefined, propertyDetail: PropertyDetail, userAccessControls: UserAccessControl, comparableSales?: ComparableSalesResultPayload) {
    super();
    let reportPreferences = userReportPreferences?.propertyReportPreference?.reportPreferences;
    if (reportPreferences) {
      if(comparableSales){
        this.reportTitle = 'Comparable Sales Report';
      } else {
        this.reportTitle = reportPreferences.pdfClientReport ? reportPreferences.pdfClientReport : 'Property Details Report';
      }
      this.cover = reportPreferences.pdfCover;
      this.profileInfo = reportPreferences.pdfProfileInfo;
    }
    this.uom = userReportPreferences?.genericPreference?.uomDistance ? userReportPreferences?.genericPreference?.uomDistance.toLowerCase() : 'metric';

    this.propertyInfo = new PdfReportPropertyInfoConfiguration(propertyDetail, userAccessControls, reportPreferences);

    this.areaSalesInfo = new PdfReportAreaSaleConfiguration(propertyDetail, userReportPreferences, userAccessControls, comparableSales);
    this.marketAnalytics = new PdfReportMarketAnalyticsConfiguration(propertyDetail, userAccessControls);
  }

  reportTitle: string;
  cover: boolean;
  uom: string;
  propertyInfo: PdfReportPropertyInfoConfiguration;
  areaSalesInfo: PdfReportAreaSaleConfiguration;
  marketAnalytics: PdfReportMarketAnalyticsConfiguration;
  profileInfo: boolean;

}
