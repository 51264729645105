<h1 id="spd-h1-title" mat-dialog-title>
  Create a New Comparables Report for Subject Property
</h1>
<div class="dialog-content" mat-dialog-content>
  <h2 class="mt-4">Please enter the new report information.</h2>

  <div class='geo-flex-column get-dense-3 remove-margin display:flex flex-direction:column'>
    <mat-form-field id="csr-add-rn-fld" appearance="outline" required>
      <mat-label id="acd-lbl-rn">Report Name*</mat-label>
      <input [(ngModel)]="param.reportName" id="csr-add-rn" matInput name="csr-add-rn" placeholder="">
      @if(formNotValid && showError) {
        <mat-hint>Report Name is required</mat-hint>
      }
    </mat-form-field>

    <mat-form-field id="csr-add-cn-fld" appearance="outline">
      <mat-label id="acd-lbl-cn">Client Name</mat-label>
      <input [(ngModel)]="param.clientName" id="csr-add-cn" matInput name="csr-add-cn" placeholder="">
    </mat-form-field>

    <mat-form-field id="csr-add-jn-fld" appearance="outline">
      <mat-label id="acd-lbl-jn">Job Number</mat-label>
      <input [(ngModel)]="param.jobNumber" id="csr-add-jn" matInput name="csr-add-jn" placeholder="">
    </mat-form-field>

    <mat-form-field id="csr-add-cm-fld" appearance="outline">
      <mat-label id="acd-label-cm">Comments</mat-label>
      <textarea [(ngModel)]="param.comments" id="csr-add-cm" matInput name="csr-add-cm" placeholder="" maxlength="300"></textarea>
    </mat-form-field>
    <div>{{ getRemainingCharacters() }} - characters remaining</div>
  </div>
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>

  <button id="btn-ok" mat-button (click)="sendParams()" class="dialog-accept-btn" cdkFocusInitial color="primary">Ok</button>
  <button id="btn-close" mat-button mat-dialog-close class="dialog-close-btn">Cancel</button>
</div>
