import { BaseModel } from '../base-model';
import { Message } from './message';
import * as _ from 'lodash';

export class PriorityMessage extends BaseModel {
    constructor(priorityMessage?: PriorityMessage) {
        super(priorityMessage);

        if (priorityMessage) {
            if (Array.isArray(priorityMessage.messages)) {
                this.messages = priorityMessage.messages.map(value => {
                    return new Message(value);
                });
            }
        }
    }

    private messages: Message[];

    public getMessages(): Message[] {
        return this.messages;
    }

    public setMessages(messages: Message[]): void {
        this.messages = messages;
    }

    public hasMessages = () => {
        return !_.isEmpty(this.getMessages());
    }
}
