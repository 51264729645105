import { HttpClient } from "@angular/common/http";
import { baseUrl } from "../system";
import {inject, Injectable} from "@angular/core";
import {map, catchError, throwError, Observable, of} from 'rxjs';
import * as _ from 'lodash';
import { LoggerService } from "../log/logger.service";

@Injectable({
    providedIn: 'root'
})
export class HeatmapSearchService {

    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    getHeatmapBlocks = (nelat: number, nelng: number, swlat: number, swlng: number, from: number, to: number, iscondo: string): Observable<any> => {

        let fromDate = new Date(from);
        let toDate = new Date(to);
        this.loggerService.logDebug('heatmap payload: nelat[' + nelat + '] nelng[' + nelng + '] swlat[' + swlat + '] swlng[' + swlng + '] from[' + from + ' ' + fromDate.toDateString() + '] to[' + to +  ' ' + toDate.toDateString() + '] iscondo[' + iscondo + ']');

        const url = baseUrl + '/heatmap/blocks?';
        return this.https.get<any>(url, {
            headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Accept': 'application/json'
            },
            params: {
                nelat: nelat,
                nelng: nelng,
                swlat: swlat,
                swlng: swlng,
                from: from,
                to: to,
                iscondo: iscondo
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return {};
                }
            }),
            catchError((err) => {
                this.loggerService.logError('error getting comparables sales by radius', err);
                return throwError(err);
            })
        );
    }

  getHeatmapNhood = (nelat: number, nelng: number, swlat: number, swlng: number): Observable<any> => {

      this.loggerService.logDebug('heatmap payload: nelat[' + nelat + '] nelng[' + nelng + '] swlat[' + swlat + '] swlng[' + swlng + ']');

      const url = baseUrl + '/heatmap/neighbourhood/propertyRentalPercent?';
      return this.https.get<any>(url, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          nelat: nelat,
          nelng: nelng,
          swlat: swlat,
          swlng: swlng
        }
      }).pipe(
        map(response => {
          if (!_.isEmpty(response)) {
            return response;
          } else {
            return {};
          }
        }),
        catchError((err) => {
          this.loggerService.logError('error getting neighbourhood Environics Data by radius', err);
          return throwError(err);
        })
      );
  }

  getHeatmapNhoodIncome = (nelat: number, nelng: number, swlat: number, swlng: number): Observable<any> => {

    this.loggerService.logDebug('heatmap payload: nelat[' + nelat + '] nelng[' + nelng + '] swlat[' + swlat + '] swlng[' + swlng + ']');

    const url = baseUrl + '/heatmap/neighbourhood/householdIncome?';
    return this.https.get<any>(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      },
      params: {
        nelat: nelat,
        nelng: nelng,
        swlat: swlat,
        swlng: swlng
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response;
        } else {
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError('error getting neighbourhood household Income Data by radius', err);
        return throwError(err);
      })
    );
  }
}
