<!-- @defer(on viewport){ -->
<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">
  <section class="pr-shutter-middle-body-full sm:pr-shutter-middle-body">

    <div>
      <p id="rr-desc-label" class="pr-body-text">
        The Residential Relocation Report provides an overview of real estate transactions that have occurred within the specified Land Registry Office (LRO), Municipality or Forward Sortation Area (FSA), within a given time frame. The
        information included in this report has been sourced using Teranet's exclusive land registry data.
      </p>
    </div>
    <mat-divider></mat-divider>

    <h2 id="rr-h2-reports" class="mt-7 no-print">{{productsForPurchaseLabel}}</h2>
     <p id="rr-reports-desc" class="mt-4">{{productsForPurchaseLabelDesc}}</p>

    @if (carouselProducts && carouselProducts.length == 0) {
    <div class="carousel-products-loading">
      <div id="rr-no-reports-label">{{noProductsForPurchaseLabel}}</div>
    </div>
    }

    @if (carouselProductsLoading) {
      <div class="carousel-products-loading">
        <img id="rr-img-refresh" src="assets/img/icon-refresh.gif">
      </div>
    }

    @if (!carouselProductsLoading && carouselProducts && carouselProducts.length > 0) {
      <div class="carousel-container no-print" [ngClass]="{'flex justify-center': carouselProducts.length < 3}">
        <p-carousel id="rr-reports-carousel" [circular]="false" [numScroll]="1" [numVisible]="3" [responsiveOptions]="responsiveOptions" [value]="carouselProducts" [showIndicators]="false" [showNavigators]="showNavigators">
          <ng-template let-product pTemplate="item">
            <div class="carousel-card">
              <gema3g-single-catalogue-product
                class="carousel-card__block"
                [product]="product"
                [selectedProperty]="selectedProperty">
              </gema3g-single-catalogue-product>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    }

  </section>
</gema3g-property-report-layout>
<!-- } @placeholder {
  <section class="pr-shutter-middle-body-full sm:pr-shutter-middle-body">
    <div>
      <p id="rr-desc-label-placeholder" class="md:text-lg">
        Loading data...
      </p>
    </div>
  </section>
} -->
