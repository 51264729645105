<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">
  <section class="pr-shutter-middle-body-full sm:pr-shutter-middle-body">

    <div class="flex flex-col dense-2">
      @if (hasSalesHistory) {
        <h4 id="vs-h4-sh" class="py-2">Sales History</h4>
      }
      @if (!hasSalesHistory) {
        <div id="vs-sh-na" class="pr-no-data-gray-background py-2">Sales History Not Available</div>
      }


      <div id="vs-tbl-sh-container" class="hidden overflow-auto">
        <table id="vs-tbl-sh" [dataSource]="dataSource" class="mat-elevation-z2 standard-table" mat-table matSort>

          <ng-container matColumnDef="saleDate">
            <th id="vs-tbl-hdr-sd" *matHeaderCellDef mat-header-cell mat-sort-header>Sale Date</th>
            <td id="vs-tbl-data-sd-{{row.registrationDate | mediumDate : true}}" *matCellDef="let row" mat-cell> {{ row.registrationDate | mediumDate : true }}</td>
          </ng-container>

          <ng-container matColumnDef="considerationAmt">
            <th id="vs-tbl-hdr-sa" *matHeaderCellDef mat-header-cell mat-sort-header>Sale Amount</th>
            <td id="vs-tbl-data-sa-{{row.registrationDate | mediumDate : true}}" *matCellDef="let row" mat-cell> {{ row.considerationAmt | currency : 'USD' : 'symbol' : '1.0-0' }}</td>
          </ng-container>

          @if (userAccessControls.displayInstrumentNum) {
            <ng-container matColumnDef="instrumentNum">
              <th id="vs-tbl-hdr-i" *matHeaderCellDef mat-header-cell mat-sort-header>Instrument</th>
              <td id="vs-tbl-data-i-{{row.registrationDate | mediumDate : true}}" *matCellDef="let row" mat-cell> {{ row.instrumentNum }}</td>
            </ng-container>
          }

          <ng-container matColumnDef="instrumentSubTypeDescription">
            <th id="vs-tbl-hdr-type" *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
            <td id="vs-tbl-data-type-{{row.registrationDate | mediumDate : true}}" *matCellDef="let row" mat-cell> {{ row.instrumentSubTypeDescription }}</td>
          </ng-container>

          @if (userAccessControls.displayPartyTo) {
            <ng-container matColumnDef="partyTo">
              <th id="vs-tbl-hdr-pt" *matHeaderCellDef mat-header-cell>Party To</th>
              <td id="vs-tbl-data-pt-{{row.registrationDate | mediumDate : true}}" *matCellDef="let row" mat-cell> {{ row.partyTo }}</td>
            </ng-container>
          }

          <ng-container matColumnDef="notes">
            <th id="vs-tbl-hdr-notes" *matHeaderCellDef mat-header-cell>Notes</th>
            <td *matCellDef="let row; index as i" mat-cell>
              <div class="flex flex-col">
                <div id="vs-tbl-data-notes-empty-{{row.registrationDate | mediumDate : true}}">
                  {{ i == 0 ? row.notes : "" }}
                </div>
                <div>
                  @if (i == 0) {
                    @for (pin of row.deededTogetherPins | slice:0:limitDeededTogetherPins; track pin) {
                      <span>
                        <a id="vs-tbl-link-notes-{{pin}}" (click)="openPropertyReport(pin)" class="anchor mr-2">{{ pin }}</a>
                      </span>
                    }
                    @if (row.deededTogetherPins?.length > 3) {
                      <span>
                        <a id="vs-tbl-link-notes-m-or-l" (click)="changeMaxDeededTogetherPins(row.deededTogetherPins?.length)" class="anchor">{{ limitDeededTogetherPins == maxDeededTogetherPins ? 'More' : 'Less' }}</a>
                      </span>
                    }
                  }
                </div>
              </div>
            </td>
          </ng-container>

          <tr id="vs-tbl-row-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr id="vs-tbl-row-{{row.registrationDate | mediumDate : true}}" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

        </table>

        @if (salesHistoryLength > 5) {
          <ng-container>
            <mat-divider></mat-divider>
            <mat-paginator
              id="vs-paginator-tbl"
              [length]="salesHistoryLength"
              [pageIndex]="salesHistoryPageIndex"
              [pageSizeOptions]="salesHistoryPageSizes"
              [pageSize]="salesHistoryPageSizes[1]"
              (page)="onPageChange($event)"
              showFirstLastButtons>
            </mat-paginator>
          </ng-container>
        }
      </div>

    </div>

    @if (userAccessControls.propertyReportAssessmentAccess && userAccessControls.assessedValueGraphAccess) {
      <div class="flex flex-col mt-8 print-width-80" id="chart-valuation-sales">
        <h4 id="vs-h4-av">Assessed Value</h4>
        <div id="vs-av-fusionchart" class="margin-auto print-width-80">
          <fusioncharts
            (initialized)="initialized($event)"
            [dataEmptyMessage]="ds.valuationSalesDataEmptyMessage"
            [dataSource]="valuationSalesDataSource"
            dataFormat="json"
            height="{{defaultChartHeight}}"
            type="mscombi2d"
            width="100%"
          ></fusioncharts>
        </div>
      </div>
    }

    <mat-divider></mat-divider>

    <h2 id="vs-h2-reports" class="mt-7 no-print">{{productsForPurchaseLabel}}</h2>
     <p id="vs-reports-desc" class="mt-4">{{productsForPurchaseLabelDesc}}</p>

    @if (carouselProducts && carouselProducts.length == 0) {
      <div class="carousel-products-loading">
        <div id="vs-no-reports-label">{{noProductsForPurchaseLabel}}</div>
      </div>
    }

    @if (carouselProductsLoading) {
      <div class="carousel-products-loading">
        <img id="vs-img-refresh" src="assets/img/icon-refresh.gif">
      </div>
    }

    @if (!carouselProductsLoading && carouselProducts && carouselProducts.length > 0) {
      <div class="carousel-container no-print" [ngClass]="{'flex': carouselProducts.length < 3}">
        <p-carousel id="vs-reports-carousel" [circular]="false" [numScroll]="1" [numVisible]="3" [responsiveOptions]="responsiveOptions" [value]="carouselProducts" [showIndicators]="false" [showNavigators]="showNavigators">
          <ng-template let-product pTemplate="item">
            <div class="carousel-card" [ngClass]="{'mr-5': carouselProducts.length === 2}">
              <gema3g-single-catalogue-product
                class="carousel-card__block"
                [product]="product"
                [selectedProperty]="selectedProperty">
              </gema3g-single-catalogue-product>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    }

  </section>
</gema3g-property-report-layout>
