import { DataService } from "../../../shared/service/data.service";
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";
import { inject } from "@angular/core";
import { PropertyReportService } from "../../../shared/service/property-report.service";
import { LoggerService } from "src/app/shared/service/log/logger.service";

export class PropertyReportComponentBase extends BaseUnsubscribe {

  loggerService = inject(LoggerService);
  propertyReportService = inject(PropertyReportService);

  public static NOT_APPLICABLE: string = DataService.NOT_APPLICABLE;
  feetUnitDisplay: string = DataService.FEET_UNIT_DISPLAY;
  milesUnitDisplay: string = DataService.MILES_UNIT_DISPLAY;

  get notApplicable() {
    return DataService.NOT_APPLICABLE;
  }

  get carouselProductsLoading(): boolean {
    return this.propertyReportService.isCarouselProductsLoading();
  }

  get productsForPurchaseLabel(): string {
    return DataService.PROPERTY_REPORT_PRODUCTS_FOR_PURCHASE_LABEL;
  }

  get noProductsForPurchaseLabel(): string {
    return DataService.PROPERTY_REPORT_NO_PRODUCTS_FOR_PURCHASE_LABEL;
  }
   get productsForPurchaseLabelDesc(): string {
    return DataService.PROPERTY_REPORT_PRODUCTS_FOR_PURCHASE_LABEL_DESC;
  }
}
