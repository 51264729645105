import {BaseModel} from "../base-model";
import {PdfReportTypeEnum} from "../../enum/pdf-report-type-enum";
import {PdfReportMyPropertyReportConfiguration} from "./input-param/pdf-report-my-property-report-configuration";
import {PdfReportBasicReportConfiguration} from "./input-param/pdf-report-basic-report-configuration";
import {PdfReportClientReportConfiguration} from "./input-param/pdf-report-client-report-configuration";
import {UserAccessControl} from "../user/user-access-control";
import {PdfReportRequestReportConfig} from "./pdf-report-request-report-config";
import {PdfReportRequestPropRequest} from "./pdf-report-request-prop-request";
import {PdfReportRequestCompsRequest} from "./pdf-report-request-comps-request";
import {PdfReportRequestUtil} from "./pdf-report-request-util";
import {ComparableSalesRequest} from "../comparables/comparable-sales-request";
import {ComparableSalesResultPayload} from "../comparables/comparable-sales-result-payload";

/**
 * This is the PDF report parameter sent to backend to request a report
 * */
export class PdfReportRequestParam extends BaseModel {

  constructor(requestParam?: PdfReportRequestParam, pin?: string, arn?: string) {
    super(requestParam);
    if (pin) {
      this.pin = pin;
    }
    if (arn) {
      this.arn = arn;
    }
    if (requestParam) {
      this.reportConfig = new PdfReportRequestReportConfig(requestParam.reportConfig);
    } else {
      this.reportConfig = new PdfReportRequestReportConfig(undefined);
    }
  }

  pin: string;
  arn: string;
  reportConfig: PdfReportRequestReportConfig;
  propRequest: PdfReportRequestPropRequest;
  compsRequest: PdfReportRequestCompsRequest;
  circleRequest: ComparableSalesRequest;
  polygonRequest: ComparableSalesRequest;
  latLongRequest: ComparableSalesRequest;
  ids: number[];

  requestType?: PdfReportTypeEnum;

  get reportName(): string {
    return this.reportConfig?.reportName ? this.reportConfig.reportName : '';
  }

  public loadFromReportParam(reportParam: PdfReportMyPropertyReportConfiguration | PdfReportBasicReportConfiguration | PdfReportClientReportConfiguration, userAccessControls: UserAccessControl, comparableSales?: ComparableSalesResultPayload) {

    this.reportConfig.reportTitle = reportParam.reportTitle;
    this.reportConfig.uom = reportParam.uom;

    if (reportParam instanceof PdfReportBasicReportConfiguration) {

      this.loadFromBasicConfig(reportParam);

    } else if (reportParam instanceof PdfReportMyPropertyReportConfiguration) {

      this.loadFromMyReportConfig(reportParam, userAccessControls, comparableSales);

    } else if (reportParam instanceof PdfReportClientReportConfiguration) {

      this.loadFromClientReportConfig(reportParam, userAccessControls, comparableSales);
    }
  }

  private loadFromBasicConfig(reportParam: PdfReportBasicReportConfiguration) {
    this.reportConfig.reportName = "BasicViewSPagePropertyReport";

    this.reportConfig.reportImages = ["terrainView", "googleView", "streetView"];

    this.reportConfig.reportSections = ["BasicViewSPageCover",
      "BasicViewSPageGoogleImg",
      "BasicViewSPagePropertyDetails",
      "BasicViewSPageAssessmentInformation",
      "BasicViewSPageSalesHistory",
      "BasicViewSPageTC",
      "RealtorCard",
      "MapImages"];

    if (reportParam.propertyInfo.propertyIsCondo) {
      this.reportConfig.reportSections.push('CondoDetailsBasicClientReport');
    }
  }

  private loadFromMyReportConfig(reportParam: PdfReportMyPropertyReportConfiguration, userAccessControls: UserAccessControl, comparableSales?: ComparableSalesResultPayload) {

    //My report does not property info for comparable sales report
    if (comparableSales) {
      this.reportConfig.reportName = "ComparableSalesReport";
      PdfReportRequestUtil.setComparableSalesReportConfig(this.reportConfig, reportParam);
      // there is no option to print municipality comparable sales
      if (comparableSales.request?.polygons?.length > 0) {
        this.polygonRequest = comparableSales.request;
      } else if (comparableSales.formWrapper.sameCondoSearch) {
        this.latLongRequest = comparableSales.request;
      } else {
        this.circleRequest = comparableSales.request;
      }
      this.ids = comparableSales.response?.sales?.filter(value => value.selected)?.map(value => {
        return value.id
      });
    } else {
      this.reportConfig.reportName = "PropertyDetailsReport";
      PdfReportRequestUtil.loadPropertyInformationSection(this.reportConfig, reportParam, userAccessControls, this.pin, this.arn);
    }
  }

  private loadFromClientReportConfig(reportParam: PdfReportClientReportConfiguration, userAccessControls: UserAccessControl, comparableSales?: ComparableSalesResultPayload) {
    this.reportConfig.reportName = "ClientReport";
    this.reportConfig.reportTitle = reportParam.reportTitle;
    this.reportConfig.uom = reportParam.uom;
    this.reportConfig.reportSections = [];
    if (reportParam.profileInfo) {
      this.reportConfig.reportSections.push('RealtorCard');
    }
    if (reportParam.cover) {
      this.reportConfig.reportSections.push('ClientCover');
    }
    if (reportParam.marketAnalytics.canDisplayHistoricalTrends && reportParam.marketAnalytics.historicalTrends && reportParam.marketAnalytics.canDisplayMarketAnalytics) {
      if (reportParam.marketAnalytics.averageSaleValueAndGrowthRates) {
        this.reportConfig.reportSections.push('AvgSalesGraph');
      }
      if (reportParam.marketAnalytics.historicalTrendsMarketTurnover && reportParam.marketAnalytics.canDisplayHistoricalTrends) {
        this.reportConfig.reportSections.push('TurnoverRateGraph');
      }
    }
    if (reportParam.marketAnalytics.marketStatistics && reportParam.marketAnalytics.canDisplayMarketAnalytics) {
      this.reportConfig.reportSections.push('MarketStatsData');
    }
    if (reportParam.marketAnalytics.canDisplayAreaHeatmaps && reportParam.marketAnalytics.areaHeatMaps && reportParam.marketAnalytics.canDisplayMarketAnalytics) {
      if (reportParam.marketAnalytics.averageSale) {
        this.reportConfig.reportSections.push('AvgSalesHM');
      }
      if (reportParam.marketAnalytics.growthRates) {
        this.reportConfig.reportSections.push('GrowthRateHM');
      }
      if (reportParam.marketAnalytics.areaHeatMapsMarketTurnover) {
        this.reportConfig.reportSections.push('TurnoverRateHM');
      }
    }

    this.propRequest = new PdfReportRequestPropRequest(this.pin, this.arn);
    this.propRequest.reportConfig = new PdfReportRequestReportConfig();
    this.propRequest.reportConfig.reportName = 'PropertyDetailsReport';
    this.propRequest.reportConfig.reportTitle = '';
    this.propRequest.reportConfig.uom = reportParam.uom;
    PdfReportRequestUtil.loadPropertyInformationSection(this.propRequest.reportConfig, reportParam, userAccessControls, this.pin, this.arn);
    this.reportConfig.reportImages = null;

    this.compsRequest = new PdfReportRequestCompsRequest(this.pin, this.arn);
    this.compsRequest.loadFromClientReportConfig(reportParam);

    if (comparableSales) {
      if (comparableSales.request?.polygons?.length > 0) {
        this.compsRequest.polygonRequest = comparableSales.request;
        this.compsRequest.circleRequest = null;
      } else if (comparableSales.formWrapper.sameCondoSearch) {
        this.compsRequest.latLongRequest = comparableSales.request;
        this.compsRequest.circleRequest = null;
      } else {
        this.compsRequest.circleRequest = comparableSales.request;
        this.compsRequest.polygonRequest = null;
      }
      this.compsRequest.ids = comparableSales.response?.sales?.filter(value => value.selected)?.map(value => {
        return value.id
      });
    }
  }
}
