<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">
    <div class="col-0">
      @if (isLoading) {
        <section class="main">
          <div id="pref-loading-msg" class="spinner">
            Please wait...
            <img id="pref-img-spinner" alt="Loading..." src="assets/img/icon-refresh.gif"/>
          </div>
        </section>
      }

      @if (!isLoading) {
        <section class="main get-dense-3">
          <form id="pref-form" [formGroup]="preferenceForm">
            <div class="display-flex px-4 py-8">
              <div>
                <button id="pref-btn-save-top" (click)="savePreferences()" color="primary" mat-raised-button [disabled]="saveButtonDisabled">Save Preferences</button>
              </div>
              <div class="flex-1"></div>
              <div>
                <a id="pref-link-reset-top" (click)="loadDefaultPreferences()" class="anchor">Reset preferences to system defaults</a>
              </div>
            </div>
            <mat-divider></mat-divider>
            @if (saving || resetting) {
              <mat-progress-bar id="pref-progress-bar" mode="indeterminate"></mat-progress-bar>
            }
            <mat-tab-group>
              <mat-tab id="pref-tab-general" label="General">
                <div class="ml-8">
                  <h3 id="pref-h3-general-search" class="header">Search</h3>
                  <div formGroupName="genericPreference">
                    <div class='geo-flex-row'>
                      <div class='geo-flex-column w-1/2'>
                        <mat-label id="pref-label-general-search-sm">Search Method</mat-label>
                        <mat-label id="pref-label-general-search-sm-def" class="definition">Default search method.</mat-label>
                      </div>
                      <div class='geo-flex-column dense-2'>
                        <mat-form-field id="pref-ff-general-search-sm" appearance="outline">
                          <mat-select formControlName="searchMethod" id="account-preference-search-method">
                            @for (sm of searchMethods; track sm) {
                              <mat-option id="pref-option-general-search-sm-{{sm.value}}" [value]="sm.value">
                                {{sm.viewValue}}
                              </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class='geo-flex-row'>
                      <div class='geo-flex-column w-1/2'>
                        <mat-label id="pref-label-general-search-location">Location</mat-label>
                        <mat-label id="pref-label-general-search-location-def" class="definition">Default search area based on Land Registry Office or Municipality.</mat-label>
                      </div>
                      <div class='geo-flex-column dense-2'>
                        <mat-form-field id="pref-ff-general-search-location" appearance="outline">
                          <mat-select formControlName="lro" id="account-preference-lro">
                            @for (lro of lros; track lro) {
                              <mat-option id="pref-option-general-search-location-{{lro.id}}" [value]="lro.id">
                                {{lro.name}} ({{lro.id}})
                              </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <h3 id="pref-h3-general-measure" class="header">Measurements</h3>
                    <div class='geo-flex-row'>
                      <div class='geo-flex-column w-1/2'>
                        <mat-label id="pref-label-general-measure-uom">Units of Measure</mat-label>
                        <mat-label id="pref-label-general-measure-uom-def" class="definition">Default unit of measurement for properties.
                          Note: Distances are always displayed in metric.
                          <fa-icon
                            id="pref-general-measure-uom-tooltip-icon"
                            class="fa-sm text-[#428BCA]"
                            [icon]="faCircleInfo"
                            [satPopoverAnchor]="uomPopoverTooltip"
                            (mouseover)="uomPopoverTooltip.open()"
                            (mouseout)="uomPopoverTooltip.close()">
                          </fa-icon>
                          <sat-popover #uomPopoverTooltip [autoFocus]="false" [restoreFocus]="false" verticalAlign="above"
                            openTransition="500ms ease-out" closeTransition="100ms ease-in">
                            <gema3g-tooltip [content]="unitOfMeasureToolTip"></gema3g-tooltip>
                          </sat-popover>
                        </mat-label>
                      </div>
                      <div class='geo-flex-column'>
                        <mat-button-toggle-group formControlName="uomDistance" multiple="false" id="account-preference-uom-distance">
                          <mat-button-toggle id="pref-button-toggle-general-measure-uom-metric" class="button-toggle" value="METRIC">Metric</mat-button-toggle>
                          <mat-button-toggle id="pref-button-toggle-general-measure-uom-imperial" class="button-toggle" value="IMPERIAL">Imperial</mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <h3 id="pref-h3-general-md" class="header">Map Display</h3>
                    <div class='geo-flex-row'>
                      <div class='geo-flex-column w-1/2'>
                        <mat-label id="pref-label-general-md-mv">Map View</mat-label>
                        <mat-label id="pref-label-general-md-mv-def" class="definition">Set Map View default.</mat-label>
                      </div>
                      <div class='geo-flex-column'>
                        <mat-button-toggle-group formControlName="mapType" multiple="false" id="account-preference-map-type">
                          <mat-button-toggle id="pref-button-toggle-general-md-mv-map" class="button-toggle" value="MAP">Map</mat-button-toggle>
                          <mat-button-toggle id="pref-button-toggle-general-md-mv-aerial" class="button-toggle" value="AERIAL">Aerial</mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class='geo-flex-row'>
                      <div class='geo-flex-column w-1/2'>
                        <mat-label id="pref-label-general-md-layers">Layers</mat-label>
                        <mat-label id="pref-label-general-md-layers-def" class="definition">Set default layer options in main map.</mat-label>
                      </div>
                      <div class='geo-flex-column'>
                        <div formArrayName="layers">
                          @for (layer of genericPreferenceLayers.controls; track layer; let i = $index) {
                            <div class="geo-flex-row">
                              <div formGroupName="{{ i }}">
                                <mat-checkbox color="primary" formControlName="booleanValue" id="account-preference-layer={{i}}">
                                  {{getGenericPreferenceLayerDisplayLabel(layer.get('key')?.value, true)}}
                                </mat-checkbox>
                                <p class="layer-definition">
                                  <mat-label id="pref-label-general-md-layers-{{i}}" class="definition m-0 pl-11">
                                    {{getGenericPreferenceLayerDisplayLabel(layer.get('key')?.value, false)}}
                                  </mat-label>
                                </p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </mat-tab>
              <mat-tab id="pref-tab-cs" label="Comparables Search">
                <div class="ml-8">
                  <div formGroupName="comparablesPreference">
                    <div formGroupName="searchCriteria">
                      <h3 id="pref-h3-cs-criteria">Comparables Search Criteria</h3>
                      <div class='geo-flex-row'>
                        <div class='geo-flex-column w-1/2'>
                          <mat-label id="pref-label-cs-criteria-sas">Search Area Selection</mat-label>
                          <mat-label id="pref-label-cs-criteria-sas-def" class="definition">Set default area search method.</mat-label>
                        </div>
                        <div class='geo-flex-column'>
                          <mat-button-toggle-group formControlName="defaultSearchAreaSelection" id="account-preference-search-area-selection">
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-sas-radius" class="button-toggle" value="radius">Radius</mat-button-toggle>
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-sas-polygon" class="button-toggle" value="polygon">Polygon</mat-button-toggle>
                            @if (userAccessControl?.isMpsUser) {
                              <mat-button-toggle id="pref-button-toggle-cs-criteria-sas-muni" class="button-toggle" value="municipality">Municipality</mat-button-toggle>
                            }
                          </mat-button-toggle-group>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div></div>
                      <div class='geo-flex-row'>
                        <div class='geo-flex-column w-1/2'>
                          <mat-label id="pref-label-cs-criteria-sds">Sale Date Selection</mat-label>
                          <mat-label id="pref-label-cs-criteria-sds-def-1" class="definition">Choose default Sale Date range</mat-label>
                          <mat-label id="pref-label-cs-criteria-sds-def-2" class="definition">For Preset Range options, today’s date is the default end date.</mat-label>
                        </div>
                        <div class='geo-flex-column'>
                          <mat-button-toggle-group formControlName="lastDaysDateRangeType" class="w-fit" id="account-preference-last-days-range-type">
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-sds-preset" (click)="setLastDaysDateRange('LAST_6_MONTHS')" class="button-toggle" value="PRESET">Preset</mat-button-toggle>
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-sds-cal" (click)="setLastDaysDateRange(null)" class="button-toggle" value='CALENDAR'>Calendar</mat-button-toggle>
                          </mat-button-toggle-group>
                          @if (lastDaysDateRange) {
                            <div>
                              <p id="pref-cs-criteria-sds-preset-label">Preset Ranges</p>
                              <mat-button-toggle-group class="mb-1" formControlName="lastDaysDateRange" id="account-preference-last-days-range">
                                <mat-button-toggle id="pref-button-toggle-cs-criteria-sds-30D" class="button-toggle" value='LAST_30_DAYS'>30 Days</mat-button-toggle>
                                <mat-button-toggle id="pref-button-toggle-cs-criteria-sds-3MO" class="button-toggle" value='LAST_90_DAYS'>3 Months</mat-button-toggle>
                                <mat-button-toggle id="pref-button-toggle-cs-criteria-sds-6MO" class="button-toggle" value='LAST_6_MONTHS'>6 Months</mat-button-toggle>
                                <mat-button-toggle id="pref-button-toggle-cs-criteria-sds-1Y" class="button-toggle" value='LAST_YEAR'>1 Year</mat-button-toggle>
                              </mat-button-toggle-group>
                            </div>
                          }
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div class='geo-flex-row'>
                        <div class='geo-flex-column w-1/2'>
                          <mat-label id="pref-label-cs-criteria-pt">Property Type</mat-label>
                          <mat-label id="pref-label-cs-criteria-pt-def" class="definition">Filter search results by property type.</mat-label>
                        </div>
                        <div class='geo-flex-column'>
                          <mat-button-toggle-group formControlName="freeholdOrCondoOrAll" id="account-preference-freehold-or-condo" class="mb-1">
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-pt-all" (click)="setOwnershipType('ALL')" class="button-toggle" value="ALL">All</mat-button-toggle>
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-pt-fh" (click)="setOwnershipType('FREEHOLD')" class="button-toggle" value="FREEHOLD">Freehold</mat-button-toggle>
                            <mat-button-toggle id="pref-button-toggle-cs-criteria-pt-condo" (click)="setOwnershipType('CONDO')" class="button-toggle" value="CONDO">Condo</mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div class='geo-flex-row'>
                        <div class='geo-flex-column w-1/2'>
                          <mat-label id="pref-label-cs-criteria-sa">Sale amount</mat-label>
                          <mat-label id="pref-label-cs-criteria-sa-def" class="definition">Filter search results by sale amount.</mat-label>
                        </div>
                        <div class='geo-flex-column dense-2 w-1/5'>
                          <mat-form-field id="pref-ff-cs-criteria-sa-from" appearance="outline">
                            <mat-label id="pref-label-cs-criteria-sa-from">From</mat-label>
                            <input type="text"
                              matInput
                              formControlName="minAmountDisplay"
                              [matAutocomplete]="auto"
                              id="account-preference-min-amount"
                              >
                              <mat-icon id="pref-icon-down" matSuffix>keyboard_arrow_down</mat-icon>
                              <mat-autocomplete id="pref-autocomplete-cs-criteria-sa-from" #auto="matAutocomplete">
                                @for (price of minPriceRange; track price) {
                                  <mat-option id="pref-option-cs-criteria-sa-from-{{price.id}}" [value]="price.id">
                                    {{price.value}}
                                  </mat-option>
                                }
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                          <div class='geo-flex-column dense-2 w-1/10'>
                            <div class="min-max-divider"></div>
                          </div>
                          <div class='geo-flex-column dense-2 w-1/5'>
                            <mat-form-field id="pref-ff-cs-criteria-sa-to" appearance="outline">
                              <mat-label id="pref-label-cs-criteria-sa-to">To</mat-label>
                              <input type="text"
                                matInput
                                formControlName="maxAmountDisplay"
                                [matAutocomplete]="auto2"
                                id="account-preference-max-amount"
                                >
                                <mat-icon id="pref-icon-down" matSuffix>keyboard_arrow_down</mat-icon>
                                <mat-autocomplete id="pref-autocomplete-cs-criteria-sa-to" #auto2="matAutocomplete">
                                  @for (price of maxPriceRange; track price) {
                                    <mat-option id="pref-option-cs-criteria-sa-to-{{price.id}}" [value]="price.id">
                                      {{price.value}}
                                    </mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="geo-flex-row error-row">
                            <div class="geo-flex-column w-1/2"></div>
                            <div class="geo-flex-column">
                              @if (isSaleAmountNotValid) {
                                <mat-error id="pref-error-cs-criteria-sa">
                                  'To' sale amount should be greater than 'From' amount.
                                </mat-error>
                              }
                            </div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class='geo-flex-row'>
                            <div class='geo-flex-column w-1/2'>
                              <mat-label id="pref-label-cs-criteria-ls">Lot Size (sq. {{isImperial() ? 'f' : 'm'}})</mat-label>
                              <mat-label id="pref-label-cs-criteria-ls-def" class="definition">Filter search results lot size range.</mat-label>
                            </div>
                            <div class='geo-flex-column dense-2 w-1/5'>
                              <mat-form-field id="pref-ff-cs-criteria-ls-from" appearance="outline">
                                <mat-label id="pref-label-cs-criteria-ls-from">From</mat-label>
                                <mat-select formControlName="minArea" id="account-preference-min-area">
                                  @for (area of minAreaRange; track area) {
                                    <mat-option id="pref-option-cs-criteria-ls-from-{{area.id}}"
                                      [value]="area.id">
                                      {{isImperial() ? area.imperialDisplayValue : area.metricDisplayValue}}
                                    </mat-option>
                                  }
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class='geo-flex-column dense-2 w-1/10'>
                              <div class="min-max-divider"></div>
                            </div>
                            <div class='geo-flex-column dense-2 w-1/5'>
                              <mat-form-field id="pref-ff-cs-criteria-ls-to" appearance="outline">
                                <mat-label id="pref-label-cs-criteria-ls-to">To</mat-label>
                                <mat-select formControlName="maxArea" id="account-preference-max-area">
                                  @for (area of maxAreaRange; track area) {
                                    <mat-option id="pref-option-cs-criteria-ls-to-{{area.id}}"
                                      [value]="area.id">
                                      {{isImperial() ? area.imperialDisplayValue : area.metricDisplayValue}}
                                    </mat-option>
                                  }
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="geo-flex-row error-row-last">
                            <div class="geo-flex-column w-1/2"></div>
                            <div class="geo-flex-column">
                              @if (isSaleAreaNotValid) {
                                <mat-error id="pref-error-cs-criteria-ls">
                                  'To' lot size should be larger than 'From' lot size.
                                </mat-error>
                              }
                            </div>
                          </div>
                          <mat-divider></mat-divider>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab id="pref-tab-pdf" label="PDF Property Report">
                    <div class="ml-8">
                      <div formGroupName="propertyReportPreference">
                        <div formGroupName="reportPreferences">
                          <div class="py-2">
                            <span id="pref-pdf-def">Use the options to choose what to include by default in your PDF Property Report.</span>
                          </div>
                          <mat-divider></mat-divider>
                          <h3 id="pref-h3-pdf-report">Default setting for print PDF reports: My Property report and My Client report</h3>
                          <div class="geo-flex-row-nowrap">
                            <div class="geo-half-column">
                              <mat-divider></mat-divider>
                              <div class="geo-flex-row-nowrap">
                                <div class="geo-flex-column w-10/12">
                                  <mat-label id="pref-label-pdf-report-cover">Cover Page</mat-label>
                                  <mat-label id="pref-label-pdf-report-cover-def" class="definition">Displays the report title, property address, and your email contact information.</mat-label>
                                  <mat-form-field id="pref-ff-pd-report-cover-title" appearance="outline" class="input-field">
                                    <mat-label id="pref-label-pdf-report-cover-title">Report Title</mat-label>
                                    <input formControlName="pdfClientReport" id="account-preference-pdfClientReport" matInput placeholder="">
                                  </mat-form-field>
                                </div>
                                <div class="geo-flex-column w-2/12">
                                  <mat-slide-toggle color="primary" formControlName="pdfCover" id="account-preference-pdfCover"></mat-slide-toggle>
                                </div>
                              </div>
                              <mat-divider></mat-divider>
                              <div class="geo-flex-row-nowrap">
                                <div class="geo-flex-column w-10/12">
                                  <mat-label id="pref-label-pdf-report-realtor">Profile Information</mat-label>
                                  <mat-label id="pref-label-pdf-report-realtor-def" class="definition">Displays your profile information.</mat-label>
                                </div>
                                <div class="geo-flex-column w-2/12">
                                  <mat-slide-toggle color="primary" formControlName="pdfProfileInfo" id="account-preference-pdfProfileInfo"></mat-slide-toggle>
                                </div>
                              </div>
                              <mat-divider></mat-divider>
                              @if (userAccessControl.propertyReportAccess) {
                                <div class="geo-flex-row-nowrap">
                                  <div class="geo-flex-column w-10/12">
                                    <mat-label id="pref-label-pdf-report-pd">Property Details</mat-label>
                                    <mat-label id="pref-label-pdf-report-pd-def" class="definition">Select default map view of property.</mat-label>
                                    <mat-checkbox class="pl-1" color="primary" formControlName="pdfStreetView" id="account-preference-pdfStreetView">
                                      Street View
                                    </mat-checkbox>
                                    <mat-checkbox class="pl-1" color="primary" formControlName="pdfAerialMap" id="account-preference-pdfAerialMap">
                                      Aerial Map
                                    </mat-checkbox>
                                  </div>
                                  <div class="geo-flex-column w-2/12">
                                    <mat-slide-toggle color="primary" formControlName="pdfPropertyDetails" id="account-preference-pdfPropertyDetails"></mat-slide-toggle>
                                  </div>
                                </div>
                              }
                              <mat-divider></mat-divider>
                              <div class="geo-flex-row-nowrap">
                                <div class="geo-flex-column w-10/12">
                                  <mat-label id="pref-label-pdf-report-owner">Ownership</mat-label>
                                  <mat-label id="pref-label-pdf-report-owner-def" class="definition">Property owner/Party To information.</mat-label>
                                </div>
                                <div class="geo-flex-column w-2/12">
                                  <mat-slide-toggle color="primary" formControlName="pdfOwnership" id="account-preference-pdfOwnership"></mat-slide-toggle>
                                </div>
                              </div>
                              <mat-divider></mat-divider>
                              <div class="geo-flex-row-nowrap">
                                <div class="geo-flex-column w-10/12">
                                  <mat-label id="pref-label-pdf-report-legal">Legal Description</mat-label>
                                  <mat-label id="pref-label-pdf-report-legal-def" class="definition">Unique description of property that may contain lot number, plan number, township, and other text.</mat-label>
                                </div>
                                <div class="geo-flex-column w-2/12">
                                  <mat-slide-toggle color="primary" formControlName="pdfLegalDescription" id="account-preference-pdfLegalDescription"></mat-slide-toggle>
                                </div>
                              </div>
                              <mat-divider></mat-divider>
                            </div>
                            <div class="geo-half-column">
                              @if (userAccessControl.propertyReportAssessmentAccess) {
                                <div class="geo-flex-row-nowrap">
                                  <div class="geo-flex-column w-10/12">
                                    <mat-label id="pref-label-pdf-report-ai">Assessment information</mat-label>
                                    <mat-label id="pref-label-pdf-report-ai-def" class="definition">Assessment detail information including map, assessed & phased-in values, roll number(s), property type, and measurements.</mat-label>
                                  </div>
                                  <div class="geo-flex-column w-2/12">
                                    <mat-slide-toggle color="primary" formControlName="pdfAssessmentInformation" id="account-preference-pdfAssessmentInformation"></mat-slide-toggle>
                                  </div>
                                </div>
                              }
                              <mat-divider></mat-divider>
                              <div class="geo-flex-row-nowrap">
                                <div class="geo-flex-column w-10/12">
                                  <mat-label id="pref-label-pdf-report-ls">Lot Size</mat-label>
                                  <mat-label id="pref-label-pdf-report-ls-def" class="definition">Area and perimeter measurements of the property.</mat-label>
                                </div>
                                <div class="geo-flex-column w-2/12">
                                  <mat-slide-toggle color="primary" formControlName="pdfLotSize" id="account-preference-pdfLotSize"></mat-slide-toggle>
                                </div>
                              </div>
                              <mat-divider></mat-divider>
                              <div class="geo-flex-row-nowrap">
                                <div class="geo-flex-column w-10/12">
                                  <mat-label id="pref-label-pdf-report-sh">Sales History</mat-label>
                                  <mat-label id="pref-label-pdf-report-sh-def" class="definition">List of sales activity on the property.</mat-label>
                                </div>
                                <div class="geo-flex-column w-2/12">
                                  <mat-slide-toggle color="primary" formControlName="pdfSalesHistory" id="account-preference-pdfSalesHistory"></mat-slide-toggle>
                                </div>
                              </div>
                              <mat-divider></mat-divider>
                              @if (userAccessControl.propertyReportDemographicsAccess) {
                                <div class="geo-flex-row-nowrap">
                                  <div class="geo-flex-column w-10/12">
                                    <mat-label id="pref-label-pdf-report-dg">Demographics</mat-label>
                                    <mat-label id="pref-label-pdf-report-dg-def" class="definition">Population, household, Socio-Economic, and Cultural statistical data.</mat-label>
                                    <mat-checkbox class="pl-1" color="primary" formControlName="pdfDominantMarketGroup" id="account-preference-pdfDominantMarketGroup">
                                      Dominant Market Group
                                    </mat-checkbox>
                                    <mat-label id="pref-label-pdf-report-dg-dmg-def" class="definition m-0 pl-11">
                                      Consumer segmentation data describing lifestyle and behavioural traits.
                                    </mat-label>
                                  </div>
                                  <div class="geo-flex-column w-2/12">
                                    <mat-slide-toggle color="primary" formControlName="pdfDemographics" id="account-preference-pdfDemographics"></mat-slide-toggle>
                                  </div>
                                </div>
                              }
                              <mat-divider></mat-divider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
                <mat-divider></mat-divider>
                <div class="display-flex px-4 py-8">
                  <div>
                    <button id="pref-btn-save-bottom" (click)="savePreferences()" color="primary" mat-raised-button [disabled]="saveButtonDisabled">Save Preferences</button>
                  </div>
                  <div class="flex-1"></div>
                  <div>
                    <a id="pref-link-reset-bottom" (click)="loadDefaultPreferences()" class="anchor">Reset preferences to system defaults</a>
                  </div>
                </div>
              </form>
            </section>
          }
        </div>
      </div>
    </div>
