@if (!userService.userHasAccessToEStoreOnly) {
  <section class="header-container">
    <mat-toolbar>
      <mat-toolbar-row>
        <!--small menu-->
        <div class="sm:hidden flex" [style.pointer-events]="userAccessControls?.restrictedAccessPointerEvents">
          <button id="header-btn-small-menu" mat-menu-item [matMenuTriggerFor]="smMenu">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu id="header-dd-small-menu" #smMenu="matMenu" yPosition="below">
            <button class="header-mobile-btn" id="header-btn-gwh" mat-menu-item [matMenuTriggerFor]="smGeoAcademySubMenu" (click)="openGwhMenu()">GeoWarehouse Academy</button>
            <button class="header-mobile-btn" id="header-btn-support" mat-menu-item [matMenuTriggerFor]="smSupportSubMenu">Support</button>

            @if (showRataLink) {
              <button class="header-mobile-btn" id="header-btn-rata" mat-menu-item (click)="openRATA()" id="sm-rata">Registration Tracker</button>
            }

            @if (mpsComparablesReportsAccess) {
              <button class="header-mobile-btn" id="header-btn-comparables" mat-menu-item (click)="openCompsReportList()" id="sm-comps-report-list">Comparables Reports</button>
            }
          </mat-menu>
          <img id="header-mini-logo" class="max-w-[17px] self-center" src="{{ logoSm }}" (click)="reloadGema3G()"/>

          <mat-menu id="header-dd-small-gwh" #smGeoAcademySubMenu="matMenu" yPosition="below">
            <button id="header-btn-gwh-overview" mat-menu-item (click)="redirectToOverview()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faListUl"></fa-icon>Overview</span>
            </button>
            <button id="header-btn-gwh-calendar" mat-menu-item (click)="redirectToTrainingCalendar()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faCalendarDays"></fa-icon>Training Calendar</span>
            </button>
            <button id="header-btn-gwh-library" mat-menu-item (click)="redirectToHowToLibrary()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faBookOpenReader"></fa-icon>How-To Library</span>
            </button>
          </mat-menu>

          <mat-menu id="header-dd-small-support" #smSupportSubMenu="matMenu" yPosition="below" (click)="openSupportMenu()">
            <button id="header-btn-support-help" mat-menu-item (click)="redirectToHelpAndSupport()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faBellConcierge"></fa-icon>Help & Support</span>
            </button>
            <button id="header-btn-support-contact" mat-menu-item (click)="redirectToContactUs()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faTowerBroadcast"></fa-icon>Contact Us</span>
            </button>
            <button id="header-btn-support-insighter" mat-menu-item (click)="redirectToInsighter()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faPersonChalkboard"></fa-icon>Become an Insighter</span>
            </button>
            <mat-divider></mat-divider>
            <button id="header-btn-support-about" mat-menu-item (click)="openAboutWindow()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faCircleInfo"></fa-icon>About</span>
            </button>
          </mat-menu>
        </div>

        <!--large menu-->
        <div class="hidden sm:block" [style.pointer-events]="userAccessControls?.restrictedAccessPointerEvents">
          <a id="header-link-gwh" class="left-link" [matMenuTriggerFor]="geoAcademyMenu">GeoWarehouse Academy</a>
          <a id="header-link-support" class="left-link" [matMenuTriggerFor]="supportMenu">Support</a>

          <mat-menu id="header-menu" #geoAcademyMenu="matMenu" yPosition="below">
            <button id="header-menu-btn-overview" mat-menu-item (click)="redirectToOverview()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faListUl"></fa-icon>Overview</span>
            </button>
            <button id="header-menu-btn-calendar" mat-menu-item (click)="redirectToTrainingCalendar()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faCalendarDays"></fa-icon>Training Calendar</span>
            </button>
            <button id="header-menu-btn-library" mat-menu-item (click)="redirectToHowToLibrary()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faBookOpenReader"></fa-icon>How-To Library</span>
            </button>
          </mat-menu>

          <mat-menu #supportMenu="matMenu" yPosition="below">
            <button id="header-menu-btn-help" mat-menu-item (click)="redirectToHelpAndSupport()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faBellConcierge"></fa-icon>Help & Support</span>
            </button>
            <button id="header-menu-btn-contact" mat-menu-item (click)="redirectToContactUs()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faTowerBroadcast"></fa-icon>Contact Us</span>
            </button>
            <button id="header-menu-btn-insighter" mat-menu-item (click)="redirectToInsighter()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faPersonChalkboard"></fa-icon>Become an Insighter</span>
            </button>
            <mat-divider></mat-divider>
            <button id="header-menu-btn-about" mat-menu-item (click)="openAboutWindow()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faCircleInfo"></fa-icon>About</span>
            </button>
          </mat-menu>

          @if (showRataLink) {
            <a id="header-link-rata" class="left-link" (click)="openRATA()" id="md-rata">Registration Tracker</a>
          }

          @if (mpsComparablesReportsAccess) {
            <a id="header-link-comparables" class="left-link" (click)="openCompsReportList()" id="md-comps-report-list">
              Comparables Reports
            </a>
          }
        </div>

        <div class="right-box">
          <mat-icon id="header-icon-store" class="mr-1">store</mat-icon>
          <a id="header-link-store" class="right-link estore_color"
            [style.pointer-events]="userAccessControls?.restrictedAccessPointerEvents"
          (click)="openEstore()">Store</a>
          <mat-icon id="header-icon-cart" class="mr-1">shopping_cart</mat-icon>
          <!-- <a id="header-link-cart" class="right-link" [style.pointer-events]="disableCartCountRenewal ? 'none' : 'all'" (click)="openCart()">Cart ({{ estoreCartCount | async }})</a> -->
          <a id="header-link-cart" class="right-link" [style.pointer-events]="disableCartCountRenewal ? 'none' : 'all'" (click)="openCart()">Cart ({{ estoreSingleCartCount.counter }})</a>
          <mat-icon id="header-icon-person">person</mat-icon>
          <a id="header-link-account" class="left-link" [matMenuTriggerFor]="accountMenu">Account</a>

          @for (themeName of themeService.themeNames; track themeName; let i = $index) {
            <!-- <a id="header-link-theme"
              (click)="themeService.set(themeName)">
              @if (i === 0 && themeService.activeTheme === 'dark') {
                <span><mat-icon>invert_colors</mat-icon></span>
              }
              @if (i === 1 && themeService.activeTheme === 'light') {
                <span><mat-icon>invert_colors</mat-icon></span>
              }
            </a> -->
          }

          <mat-menu id="header-dd-acount" #accountMenu="matMenu" yPosition="below" class="account-mat-menu">
            <div class="user-profile-header">
              <div class="user-profile-img">
                @if (user) {
                  <div>
                    @if (user.profilePhotoUrl) {
                      <img id="header-user-picture" [src]="user.profilePhotoUrlRandomized" width="40"/>
                    }
                    @if (!user.profilePhotoUrl) {
                      <img id="header-default-picture" src="../../../../assets/img/default-userprofile-photo.png" width="40"/>
                    }
                  </div>
                } @else {
                  <img id="header-user-icon" class='account-main-profile-image' src="../../../../assets/img/mygeo-icons/icon_profile.png" width="40"/>
                }
              </div>
              <div class="user-profile">
                @if (user) {
                  <div id="header-username-display">
                    <span>{{ user.fullName }}</span>
                  </div>
                }
                @if (user) {
                  <div id="header-email-display">
                    <span>{{ user.email }}</span>
                  </div>
                }
                <a id="header-link-edit-profile" class="anchor" routerLink="/account/profile">Edit profile</a>
              </div>
            </div>
            <mat-divider></mat-divider>
            <button id="header-btn-myaccount" mat-menu-item (click)="myAccount()" [disabled]="userAccessControls.GWHRSubscriptionExpired">
              <span class="fa-container padding"><fa-icon class="fa-sm" [icon]="faAddressCard"></fa-icon>My Account</span>
            </button>
            <button id="header-btn-messages" mat-menu-item (click)="myMessages()" [disabled]="userAccessControls.GWHRSubscriptionExpired">
              <span class="fa-container padding"><fa-icon class="fa-sm" [icon]="faEnvelopeOpenText"></fa-icon>Messages</span>
            </button>
            <mat-divider></mat-divider>
            <button id="header-btn-logout" mat-menu-item (click)="logout()">
              <span class="fa-container"><fa-icon class="fa-sm" [icon]="faArrowRightFromBracket"></fa-icon>Logout</span>
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <form id="jwtForm" name="jwtForm" #zendeskForm="ngForm" [action]="zendeskAction" target="_blank" method="post">
      <input type="hidden" id="jwtInput" name="jwt">
      <input type="hidden" id="returnInput" name="return_to">
      <button #submitButton id="submitButton" type="submit" style="display: none;">Submit</button>
    </form>
  </section>
}
