import {BaseModel} from "../../../base-model";
import {PdfReportPropertyDetailConfiguration} from "./pdf-report-property-detail-configuration";
import {PropertyDetail} from "../../../property/property-detail";
import {UserAccessControl} from "../../../user/user-access-control";

export class PdfReportMarketAnalyticsConfiguration extends BaseModel {

  constructor(propertyDetail: PropertyDetail, userAccessControls: UserAccessControl ) {
    super();
    if(propertyDetail){
      this.canDisplayMarketAnalytics = propertyDetail.pii?.pinXy?.commercial != true;
      this.canDisplayHistoricalTrends =
        (!!propertyDetail.pii?.address?.fullAddress && !!propertyDetail?.pii?.pin) ||
        (!!propertyDetail.pii?.address?.fullAddress && propertyDetail?.pii?.pin == null) ||
        (propertyDetail.pii?.address?.fullAddress == null && !!propertyDetail?.pii?.pin);

      this.canDisplayMarketStats = this.canDisplayHistoricalTrends;
      this.canDisplayAreaHeatmaps = propertyDetail.hasHeatmapAccess;
    }
  }


  canDisplayHistoricalTrends: boolean;
  historicalTrends: boolean = true;
  averageSaleValueAndGrowthRates: boolean = true;
  historicalTrendsMarketTurnover: boolean = true;

  canDisplayMarketStats: boolean;
  marketStatistics: boolean = true;

  canDisplayAreaHeatmaps: boolean;
  areaHeatMaps: boolean = true;
  averageSale: boolean = true;
  growthRates: boolean = true;
  areaHeatMapsMarketTurnover: boolean = true;
  canDisplayMarketAnalytics: boolean = true;



}
