import {Component, inject, OnInit} from '@angular/core';
import {UserService} from "../../../shared/service/user.service";
import {ChangePasswordRequest} from "../../../core/model/user/change-password-request";
import {ChangePasswordResponse} from "../../../core/model/user/change-password-response";
import {lastValueFrom} from "rxjs";
import {LocalStorageKey} from "../../../shared/constant/constants";
import {AuthenticationService} from "../../../shared/service/authentication.service";

@Component({
  selector: 'gema3g-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  changePasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();
  changePasswordResponse: ChangePasswordResponse;
  specialCharacters: string = "Acceptable Special Characters&#13;@, %, +, /, ', !, #, $, ^, ?, :, (, ), [, ], {, }, ~,`, -, _,&#13; . (period) and , (comma)";
  isOktaLogin: boolean = false;

  private userService = inject(UserService);
  private authService = inject (AuthenticationService);

  async ngOnInit() {
    this.isOktaLogin = this.authService.oktaLogin;
  }

  async changePassword() {
    this.changePasswordRequest.oktauserid = localStorage.getItem(LocalStorageKey.auth0UserId);
    this.changePasswordResponse = await lastValueFrom(this.userService.changePassword(this.changePasswordRequest));
    if (this.changePasswordResponse) {
      if (this.changePasswordResponse.code == 0) {
        this.changePasswordRequest.newpw = '';
        this.changePasswordRequest.oldpw = '';
        this.changePasswordRequest.confpw = '';
      }
    }
  }

  get error(): string {
    let err: string = '';
    if (this.changePasswordResponse && this.changePasswordResponse.code != 0) {
      err = this.changePasswordResponse.msg;
    }
    if (this.changePasswordRequest) {
      err += this.changePasswordRequest.errorMessage;
    }
    return err;
  }
}
