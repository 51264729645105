import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, catchError, lastValueFrom, map, Observable, of, throwError} from "rxjs";
import { baseUrl } from "./system";
import { User } from "../../core/model/user/user";
import { UserAccessControl } from "../../core/model/user/user-access-control";
import { ChangePasswordRequest } from "../../core/model/user/change-password-request";
import { ChangePasswordResponse } from "../../core/model/user/change-password-response";
import * as _ from "lodash";
import { OmnibarSearchTypeEnum } from '../../core/enum/omnibar-search-type-enum';
import { UserReportCounter } from "../../core/model/user/user-report-counter";
import { UserActivityReport } from "../../core/model/user/user-activity-report";
import { TransactionHistoryResult } from "../../core/model/transaction/transaction-history-result";
import { UserPaymentMethod } from "../../core/model/user/user-payment-method";
import { UserPreference } from "../../core/model/user/preference/user-preference";
import { UserActivity } from "../../core/model/user/user-activity";
import { MeasurementUnitService } from "./measurement-unit.service";
import { LoggerService } from './log/logger.service';
import { EstoreProductTypeEnum } from '../../core/enum/estore-product-type.enum';
import { UserProfileComponentModeEnum } from '../../core/enum/user-profile-component-mode.enum';
import { LocalStorageKey } from "../constant/constants";
import { LicenseCounter } from "../../core/model/user/license-counter";
import { LicenseCounters } from "../../core/model/user/license-counters";
import { LicensePackage } from '../../core/model/user/license-package';
import { ActivatedRoute } from '@angular/router';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public pageSize: number = 10;

  private https = inject(HttpClient);
  private measurementUnitService= inject(MeasurementUnitService);
  private loggerService =  inject(LoggerService);

  _userAccessControl: UserAccessControl = new UserAccessControl();

  private _user = new BehaviorSubject<User>(new User());
  userObservable = this._user.asObservable();

  private _ssoRequestRoute = new BehaviorSubject<ActivatedRoute | null>(null);
  ssoRequestRoute$ = this._ssoRequestRoute.asObservable();

  userHasAccessToEStoreOnly: boolean = false;

  public get user(): User {
    return this._user.getValue();
  }

  public clearUser() {
    this._user.next(new User());
    this.clearLocalStorage();
    sessionStorage.clear();
  }

  public clearLocalStorage() {
    localStorage.clear();
  }

  private getUserFromLocalStorage(): User {
    const savedUser = localStorage.getItem(LocalStorageKey.userLocalStorageKey);
    if (savedUser) {
      return new User(<User>JSON.parse(savedUser));
    }
    return new User();
  }

  public getUserPreferencesFromLocalStorage(): UserPreference {
    const savedUserPreferences = localStorage.getItem(LocalStorageKey.userPreferenceLocalStorageKey);
    if (savedUserPreferences) {
      return new UserPreference(<UserPreference>JSON.parse(savedUserPreferences));
    }
    return new UserPreference();
  }

  public reloadUser() {
    this.clearUser();
    return this.getUser();
  }

  public tryToLoadUserFromLocalStorage() {
    const user: User = this.getUserFromLocalStorage();
    if (user.businessEntityId) {
      this.completeUserInitialization(user);
      this._user.next(user);
      this.loggerService.logInfo(`loading user ${user.businessEntityId} from local storage`);
      this.loggerService.logInfo(user);
    } else {
      this.loggerService.logWarning('could not use user from local storage');
    }
  }

  private completeUserInitialization(user: User) {
    this._userAccessControl.initializeForUser(user);
    const userPref = this.getUserPreferencesFromLocalStorage();
    this.augmentUserSettingsFromPreference(user, userPref);
    this.measurementUnitService.setUnitOfMeasure(userPref);
  }

  public getUser(loadFromServer: boolean = false): Observable<User> {
    if (this.user?.businessEntityId && !loadFromServer) {
      return of(this.user);
    } else if (this.getUserFromLocalStorage()?.businessEntityId && !loadFromServer) {
      this.tryToLoadUserFromLocalStorage();
      return of(this.user);
    } else {
      const url = baseUrl + '/user';
      return this.https.get(url).pipe(
        map(resp => {
          const user: User = new User(<User>resp);
          this.completeUserInitialization(user);
          this._user.next(user);
          localStorage.setItem(LocalStorageKey.userLocalStorageKey, JSON.stringify(user));
          return user;
        }),
        catchError((err) => {
          this.loggerService.logError(err);
          return throwError(err);
        })
      );
    }
  }

  public getBEID() {
    return this.user?.businessEntityId;
  }

  public isSSOUser = (): boolean => {
    return !!this.user?.roles?.ssouser;
  }

  public getUserAccessControl(): UserAccessControl {
    return this._userAccessControl;
  }

  public changePassword(changePasswordRequest: ChangePasswordRequest): Observable<ChangePasswordResponse> {
    if (!changePasswordRequest.oktauserid) {
      const url = baseUrl + '/changepw';
      //this.loggerService.logInfo(`calling ${url}`);

      return this.https.post(url, changePasswordRequest,).pipe(
        map(resp => {
          return (<ChangePasswordResponse>resp);
        }),
        catchError((err) => {
          this.loggerService.logError(err);
          return throwError(err);
        })
      );
    } else {
      const url = `https://${environment.auth0.DOMAIN}/dbconnections/change_password`;
      let response = new ChangePasswordResponse();
      return this.https.post(
        url,
        {
          client_id: environment.auth0.CLIENT_ID,
          email: this.user.email,
          connection: environment.auth0.CONNECTION
        }, 
        {
          headers: {'Content-Type': 'application/json'},
          responseType: 'text'
        },

      ).pipe(
        map(resp => {
          response.code = resp == "We've just sent you an email to reset your password." ? 0 : 1;
          response.msg = resp;
          return response;
        }),
        catchError((err) => {
          this.loggerService.logError(err);
          response.code = 2;
          response.msg = "An error occurred while trying to contact our systems. Please try again later.";
          return of(response);
        })
      );
    }
  }

  saveUserProfile(formData: FormData, formMode: UserProfileComponentModeEnum, id: string): Observable<User> {
    let path: string = '';

    if (formMode == UserProfileComponentModeEnum.EXISTING_USER) {
      path = '/user/updateUserProfile';
    } else if (formMode == UserProfileComponentModeEnum.NEW_USER) {
      path = '/user/initializeUserProfile';
    }

    this.loggerService.logDebug(`saving user profile form for ${formMode} ${id}`);

    const url = baseUrl + path;
    //this.loggerService.logInfo(formData);
    return this.https.post(url, formData).pipe(
      map((user) => {
        return new User(<User>user);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  augmentUserSettingsFromPreference(user: User, userPreference: UserPreference) {
    if (user) {
      if (userPreference && userPreference.genericPreference) {
        user.defaultLRO = userPreference.genericPreference.lro;
        user.defaultSearch = userPreference.genericPreference.searchMethod;
      } else {
        if (_.isEmpty(user.defaultLRO)) user.defaultLRO = '80';
        if (_.isEmpty(user.defaultSearch)) user.defaultSearch = OmnibarSearchTypeEnum.ALL_VALUE;
      }
    }
  }

  getUserReportCounter(): Observable<UserReportCounter> {
    const url = baseUrl + '/user/activity/report/counter/summary?beId=' + this.user?.businessEntityId;
    return this.https.get(url).pipe(
      map(resp => {
        return <UserReportCounter>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getViewedReportHistory(pageNumber: number, pageSize: number, activityType? : string): Observable<UserActivityReport> {
    let url = `${baseUrl}/user/activity/report/counter?beId=${this.user?.businessEntityId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (activityType){
      url += `&activity=${activityType}`;
    }
    return this.https.get(url).pipe(
      map(resp => {
        return <UserActivityReport>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getTransactionReportHistory(pageNumber: number, pageSize: number, orderBy: string, orderDirection: string): Observable<TransactionHistoryResult> {
    const url = `${baseUrl}/user/transactions?beId=${this.user?.businessEntityId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    return this.https.get(url).pipe(
      map(resp => {
        return <TransactionHistoryResult>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getVaultedCreditCards(): Observable<UserPaymentMethod> {
    const url = `${baseUrl}/user/vaultedCreditCard`;
    return this.https.get(url).pipe(
      map(resp => {
        return new UserPaymentMethod(<UserPaymentMethod>resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  deleteVaultedCreditCard(externalPaymentId: string): Observable<boolean> {
    const url = `${baseUrl}/user/removeVaultedCreditCard?externalPaymentId=${externalPaymentId}`;
    return this.https.get(url).pipe(
      map(() => {
        return true;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return of(false);
      })
    );
  }

  getPreferences(): Observable<UserPreference> {
    const url = `${baseUrl}/user/preferences`;
    return this.https.get(url).pipe(
      map(resp => {
        const userPreference = new UserPreference(<UserPreference>resp);
        this.augmentUserSettingsFromPreference(this.user, userPreference);
        localStorage.setItem(LocalStorageKey.userPreferenceLocalStorageKey, JSON.stringify(userPreference));
        return userPreference;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getDefaultPreferences(): Observable<UserPreference> {
    const url = `${baseUrl}/user/preferences/default`;
    return this.https.get(url).pipe(
      map(resp => {
        return new UserPreference(<UserPreference>resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  saveUserPreferences(userPreference: UserPreference): Observable<boolean> {
    this.augmentUserSettingsFromPreference(this.user, userPreference);
    localStorage.setItem(LocalStorageKey.userPreferenceLocalStorageKey, JSON.stringify(userPreference));
    const url = baseUrl + '/user/preferences/save';
    return this.https.post(url, userPreference).pipe(
      map(() => {
        return true;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getUserActivity(limit?: number): Observable<UserActivity[]> {
    const url = `${baseUrl}/user/activity/report`;
    return this.https.get(url).pipe(
      map(resp => {
        this.loggerService.logInfo(resp);
        const result: UserActivity[] = [];
        if (Array.isArray(resp)) {
          resp.forEach(item => result.push(new UserActivity(item)));
        }
        if (limit) {
          return result.slice(0, limit);
        }
        return result;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  hasAccessToProduct = (product: EstoreProductTypeEnum): boolean => {
    switch (product) {
      case EstoreProductTypeEnum.CONDO_STATUS_CERTIFICATE:
        if (this._userAccessControl.condoInfoReportAccess && this._userAccessControl.CondoStatusCertificate) {
          return true;
        }
        break;

      case EstoreProductTypeEnum.HOODQ_SCHOOL_CATCHMENT_MAP:
        return this._userAccessControl.hoodqSchoolCatchmentMap;

      case EstoreProductTypeEnum.TERANET_LOT_DETAILS:
        return this._userAccessControl.TeranetLotDetails;

      default:
        break;
    }

    return false;
  }

  public updateLastLogin(): Observable<boolean> {
    const url = baseUrl + '/user/updateLastLogin';
    return this.https.post(url, '', {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(resp => {
        return true;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  public getUserLicenseCounters(): Observable<LicenseCounters> {
    const url = baseUrl + '/product-license/counters';
    return this.https.get(url).pipe(
      map((resp) => {
        return new LicenseCounters(<LicenseCounter[]>resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  public getUserLicensePackageDetails(): Observable<LicensePackage> {
    const url = baseUrl + '/user/userLicensePackage?beId='+ this.user.businessEntityId;
    return this.https.get(url).pipe(
      map((resp) => {
        return new LicensePackage(<LicensePackage>resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  public setSSORequestRoute(route: ActivatedRoute | null) {
    this._ssoRequestRoute.next(route);
  }


  public releaseSSORequestRouteObservable() {
    this._ssoRequestRoute.complete();
  }

  public async reloadUserAccessControlsIfNeeded() {
    // reload here user access controls if this is from a redirect from a subscription renewal request to eStore
    if(localStorage.getItem(LocalStorageKey.reloadUserAccessControls) === 'true'){
      const user = await lastValueFrom(this.getUser(true));
      localStorage.setItem(LocalStorageKey.reloadUserAccessControls, '');
    }
  }
}
