import { inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MapLayerVisibility } from '../../core/model/interface/map-layer-visibility';
import { HeatmapBlockTypeEnum } from '../../core/enum/heatmap-block-type.enum';
import { HeatmapLayerState } from '../../core/model/interface/heatmap-layer-state';
import { HeatmapNhoodLayerState } from "../../core/model/interface/heatmap-nhood-layer-state";
import { HeatmapNhoodTypeEnum } from "../../core/enum/heatmap-neighbourhood-type.enum";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() {
  }

  data: string = "test";

  public static SUPPORT_EMAIL: string = 'geowarehouse.support@teranet.ca';
  public static SUPPORT_PHONE: string = '416-360-7542';
  public static SUPPORT_TOLL_FREE: string = '1-866-237-5937';

  public static DEFAULT_LRO = '80';
  public static LRO_REQUIRED = "The LRO ensures you find the most relevant information for a property. Owner Name, Instrument, or Plan search is confined to an LRO. When searching by Address, PIN, or ARN, GeoWarehouse auto-suggests postal codes (FSA) and / or municipalities and LROs, and it auto-selects the LRO based on the center of the map view to ensure the most relevant search results.";
  public static NOT_APPLICABLE: string = 'N/A';
  public static FEET_UNIT_DISPLAY = 'ft';
  public static MILES_UNIT_DISPLAY = 'mi';
  public static SQUARE_FEET_UNIT_DISPLAY: string = 'ft\u00B2';
  public static PIN_LENGTH = 9;
  public static ASSESSMENT_ROLL_NUMBER_LENGTH = 15;
  public static ASSESSMENT_METERS_THRESHOLD = 500;
  public static DEFAULT_CHART_FONT = '12';
  public static DEFAULT_LEGAL_DESCRIPTION_VISIBLE_LENGTH = 110;
  public static MAX_DEEDED_TOGETHER_VISIBLE_PINS = 3;
  public static SEARCH_RESULTS_NOT_FOUND = 'No results were found. Please adjust your search criteria.';

  public static OMNIBAR_SEARCH_SHORTCUT_KEY_TOOLTIP: string = 'Press S anywhere to begin search';

  public static PROPERTY_REPORT_CLICK_TO_OPEN: string = 'Click for Property Report';
  public static PROPERTY_REPORT_PRODUCTS_FOR_PURCHASE_LABEL: string = 'Additional Due Diligence Tools:';
  public static PROPERTY_REPORT_NO_PRODUCTS_FOR_PURCHASE_LABEL: string = 'No additional due diligence tools.';
  public static PROPERTY_REPORT_SUMMARY_SECTION_CENTER_ON_MAP: string = 'Center property on map';
  public static PROPERTY_REPORT_SUMMARY_SECTION_PROPERTY_VIEWED_INSIGHTS: string = 'Property viewed insights';

  public static ACCOUNT_SEARCH_COMPARABLES_HISTORY_TITLE: string = 'Search Comparable History';
  public static ACCOUNT_SEARCH_COMPARABLES_HISTORY_SUB_TITLE: string = 'The Search Comparable History section shows your last 500 searches from the past two years.';
  public static ACCOUNT_SEARCH_COMPARABLES_HISTORY_WARNING_THRESHOLD: number = 100;
  public static ACCOUNT_SEARCH_COMPARABLES_HISTORY_WARNING_MESSAGE: string = 'You are reaching the limit for search comparables';

  public static ACCOUNT_PROPERTY_REPORT_HISTORY_WARNING_THRESHOLD: number = 100;
  public static ACCOUNT_PROPERTY_REPORT_HISTORY_WARNING_MESSAGE: string = 'You are reaching the limits for reports';

  public static SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED: number = 200;
  public static SEARCH_COMPARABLES_MAXIMUM_SALES_DISPLAYED: string = 'A maximum of ' + DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED + ' properties with the most recent sales dates are displayed.<br/><br/>Please refine your search criteria to narrow your search results.';
  public static SEARCH_COMPARABLES_TOP_MAXIMUM_SALES_DISPLAYED: string = 'Only the first ' + DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED + ' properties with the most recent sales dates are displayed.';
  public static SEARCH_COMPARABLES_CIRCULAR_SEARCH_BUFFER_NOT_READY: string = "The circular search buffer area is not set.";
  public static SEARCH_COMPARABLES_FIND_TOOLTIP_TEXT: string = "Click here to get a list of Comparables";
  public static SEARCH_COMPARABLES_FIND_TOOLTIP2_TEXT: string = "Search comparables count is <br>calculated upon clicking the <br>‘Find Comparables' button";
  public static SEARCH_COMPARABLES_SAME_CONDO_TOOLTIP_TEXT: string = "Search properties within";
  public static SEARCH_COMPARABLES_PROPERTY_CODE_TOOLTIP_TEXT: string = "All property codes will be used if none is selected";
  public static SEARCH_COMPARABLES_ADD_MUNICIPALITY_TOOLTIP_TEXT: string = 'Add selected municipality to filter search area';
  public static SEARCH_COMPARABLES_ADD_ALL_MUNICIPALITIES_TOOLTIP_TEXT: string = 'Add all municipalities to filter search area';
  public static SEARCH_COMPARABLES_ADD_PROPERTY_CODE_TOOLTIP_TEXT: string = 'Add selected property code to filter search area';
  public static SEARCH_COMPARABLES_ADD_ALL_PROPERTY_CODES_TOOLTIP_TEXT: string = 'Add all property codes to filter search area';
  public static SEARCH_COMPARABLES_REMOVE_MUNICIPALITY_TOOLTIP_TEXT: string = 'Remove selected municipality from the search area filter';
  public static SEARCH_COMPARABLES_REMOVE_ALL_MUNICIPALITIES_TOOLTIP_TEXT: string = 'Remove all municipalities from the search area filter';
  public static SEARCH_COMPARABLES_REMOVE_PROPERTY_CODE_TOOLTIP_TEXT: string = 'Remove selected property code from the search area filter';
  public static SEARCH_COMPARABLES_REMOVE_ALL_PROPERTY_CODES_TOOLTIP_TEXT: string = 'Remove all property codes from the search area filter';

  public static SEARCH_COMPARABLES_RESULT_EMPTY: string = "No search comparable properties to display.";
  public static SEARCH_COMPARABLES_RESULT_AUTOPAN_MAP_TOOLTIP_TEXT: string = 'If selected, always make the property marker info bubble fully visible on the map';
  public static SEARCH_COMPARABLES_RESULT_ORIENTATION_VERTICAL_TOOLTIP_TEXT: string = 'Display results vertically';
  public static SEARCH_COMPARABLES_RESULT_ORIENTATION_HORIZONTAL_TOOLTIP_TEXT: string = 'Display results horizontally';
  public static SEARCH_COMPARABLES_RESULT_ORIENTATION_MAXIMIZE_TOOLTIP_TEXT: string = 'Maximize results screen';
  public static SEARCH_COMPARABLES_RESULT_ORIENTATION_MINIMIZE_TOOLTIP_TEXT: string = 'Minimize results screen';
  public static SEARCH_COMPARABLES_RESULT_OPEN_PROPERTY_REPORT_TOOLTIP_TEXT: string = DataService.PROPERTY_REPORT_CLICK_TO_OPEN;

  public static SEARCH_COMPARABLES_INFINITY: number = 2147483647;
  public static SEARCH_COMPARABLES_INFINITY_VALUE_DISPLAY: string = "2,500,000+";

  public static COMPARABLES_REPORT_MAXIMUM_PROPERTIES_COUNT: number = 20;
  public static COMPARABLES_REPORT_MAXIMUM_PROPERTIES_COUNT_REACHED: string = "Maximum allowable properties in a report is " + DataService.COMPARABLES_REPORT_MAXIMUM_PROPERTIES_COUNT + ".";
  public static COMPARABLES_REPORT_CREATE_NEW_REPORT: string = "A new report will be created for the subject property and selected comparables will be added to it.";
  public static COMPARABLES_REPORT_ADD_TO_REPORT: string = 'Please select the target Report below and choose an "Add" button to continue.';
  public static COMPARABLES_REPORT_LOADING: string = 'Please wait while loading comparable reports info.';

  public static LOTS_OR_CONCESSIONS_NOT_FOUND = 'There are no Lots or Concessions in your selected locations.';
  public static ADD_POLYGON_PROMPT = 'Would you like to add another polygon search area?';
  public static NO_SEARCH_POLYGONS_RENDERED = 'Please draw a polygon search area on the map and try again.';
  public static INCOMPLETE_POLYGON = 'Please try again with a closed polygon.'
  public static NO_SEARCH_MUNICIPALITIES_SELECTED = 'Please select one or more municipalities and try again.';

  public static REPORT_LIMIT_WARNING: string = '<div style="padding-left: 20px; padding-right: 20px;">You have reached the maximum amount of Property Report Searches.<br/>' +
    '<br/>To add more searches to your account, please follow the instructions for your account type:<br/>' +
    '<li style="padding-left: 20px;">If you access GeoWarehouse through your Real Estate Board, email ' + DataService.SUPPORT_EMAIL + ' and advise that you received this message.' +
    '<li style="padding-left: 20px;">If you access GeoWarehouse through our main sign-on page, please go to the GeoWarehouse store and purchase "Top-Up Packages".</li>' +
    '<br/>For further assistance, please call 1-866-237-5937 </div>';

  public static PROPERTY_REPORT_LOW_BALANCE_WARNING: string = 'Property Details Reports balance is low. Please purchase reports top-up to add balance.';
  public static PROPERTY_REPORT_NO_BALANCE_WARNING: string = 'Property Details Reports have no balance. Please purchase reports top-up to add balance.';
  public static PROPERTY_REPORT_SSO_NO_BALANCE_WARNING: string = 'You have reached your report limit. Please contact Customer Support at ' + DataService.SUPPORT_EMAIL + ' for assistance.';
  public static PROPERTY_REPORT_SSO_NO_BALANCE_WARNING_HEADER: string = 'Notification';

  public static SEARCH_RESULT_NO_BALANCE_WARNING: string = 'You\'re out of reports and cannot complete the search. Please purchase a top-up package to add more reports.';

  public static DEFAULT_ACCURACY_MEASUREMENT = 'These lot boundaries are unknown. The measurement calculations could not be made.'
  public static LOW_ACCURACY_MEASUREMENT = `<div>These lot boundaries may have been adjusted to fit within the overall parcel fabric and should only be considered to be estimates. <br>` +
    `<br> THIS IS NOT A PLAN OF SURVEY. Please view the <a onclick="scrollToElementById0('pr-expansion-div-panel-ps',true)" class="anchor-white">Plans and Surveys</a> section for registered and private plans related to this property.</div>`;
  public static HIGH_ACCURACY_MEASUREMENT = `<div>Measurements are based on coordinate geometry, providing a high degree of confidence that the measurement calculations are within an acceptable variance of the registered plan dimensions.<br>` +
    `<br> THIS IS NOT A PLAN OF SURVEY. Please view the <a onclick="scrollToElementById0('pr-expansion-div-panel-ps', true)" class="anchor-white">Plans and Surveys</a>section for registered and private plans related to this property.</div>`;

  public static ADDRESS_NOT_AVAILABLE = "Address Not Available";

  public static IN_APP_TICKER_TAPE_TITLE = 'DID YOU KNOW';

  public static DEFAULT_STREETVIEW_PITCH = 2;
  public static NO_STREETVIEW_IMAGE: string = 'assets/img/no_google_streetview.png';
  public static STREETVIEW_ADDRESS_NOT_AVAILABLE = "Address Not Available";
  public static STREETVIEW_PROPERTY_PHOTO_LOADING = "Loading street view photo of the property...";
  public static STREETVIEW_PROPERTY_PHOTO_NOT_AVAILABLE = "Streetview photo of the property is not available";
  public static STREETVIEW_NOT_AVAILABLE = 'Sorry. No streetview found at this location or on this zoom level.';
  public static STREETVIEW_PROPERTY_NOT_AVAILABLE = 'Street view is not available for this property';

  public static USER_LOCATION_ERROR = "Unable to retrieve your location. Please check your web browser's settings to ensure location services are enabled.";
  public static USER_LOCATION_PROMPT_DENIED: number = -1;

  public static MAP_MARKER_BUBBLE_MISSING_ADDRESS: string = 'Address Not Available';
  public static MAP_MARKER_BUBBLE_MISSING_LEGAL_DESCRIPTION: string = 'THERE IS NO LEGAL DESCRIPTION AVAILABLE FOR THIS PROPERTY.';
  public static MAP_MARKER_BUBBLE_CONDO_BUILDING_DESCRIPTION: string = 'THIS IS A CONDOMINIUM BUILDING';
  public static MAP_MARKER_BUBBLE_MISSING_ADDRESS_DESCRIPTION: string = 'NO PROPERTY FOUND AT THIS LOCATION.';

  public static MAP_COPY_STARTED: string = 'Copying map to clipboard... please wait.';
  public static MAP_COPY_SUCCESSFUL: string = 'Map has been copied to clipboard.';
  public static MAP_COPY_FAILED: string = 'An error occurred while copying the map.';
  public static MAP_COPY_BROWSER_NOT_SUPPORTED: string = 'This browser does not support copying maps to the clipboard.';
  public static MAP_COPY_FULL_SCREEN_NOT_SUPPORTED: string = 'Copying fullscreen map to clipboard is not supported.';

  public static TORONTO_COORDINATES = new google.maps.LatLng(43.651070, -79.347015);
  public static nbhDataEmptyMessage = "Neighborhood Data is Not Available";
  public static comDataEmptyMessage = "Community Data is Not Available";
  public static cityDataEmptyMessage = "City Data is Not Available";
  public static valuationSalesDataEmptyMessage = "No assessment data available."
  public static feetToMeterFactor = 0.3048;
  public static mpacPropertyTypeCodeStartsWith = '3';

  public static MAIN_MAP_MIN_ZOOM_LEVEL = 1;
  public static MAIN_MAP_MAX_ZOOM_LEVEL = 21;
  public static AUTOSUGGEST_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL = 17;
  public static SEARCH_COMPARABLES_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL = 17;
  public static RECENT_PROPERTY_DEFAULT_ZOOM_LEVEL = 18;
  public static HEATMAP_MIN_ZOOM_LEVEL = 16;
  public static HEATMAP_MAX_ZOOM_LEVEL = 18;
  public static PLAN_SURVEY_MAP_MIN_ZOOM_LEVEL = 15;
  public static HEATMAP_NHOOD_MIN_ZOOM_LEVEL = 15;
  private mapLayerVisibility = <MapLayerVisibility>{};

  public static DEFAULT_FBS_AERIAL_VIEW_ZOOM_LEVEL: number = 99;

  public static DISTANCE_COLUMN_IN_COMPARABLE_SALES_REPORT = 'Distance column in Comparable Sales Report is always displayed in metric.';

  public static RESIDENTIAL_RELOCATION_REPORT_INSUFFICIENT_DATA = "Unfortunately, there is insufficient data for this search, and we are unable to generate a Residential Relocation Report at this time.";
  public static HOODQ_REPORT_INSUFFICIENT_DATA = "Unfortunately, there is insufficient data for this search, and we are unable to generate a HoodQ report at this time. Assessments description cannot be retrieved at this time.";

  public static ESTORE_NO_REPORTS_AVAILABLE: string = 'There are no products currently available for purchase.';
  public static ESTORE_SPECIFIC_PRODUCT: string = 'Looking for something specific? We can help! Please contact the Customer Service Center.';

  public static MPAC_NO_REPORTS_AVAILABLE: string = 'There are no MPAC reports currently available for purchase through GeoWarehouse.';

  public static DEMOGRAPHICS_DATA_NOT_AVAILABLE: string = 'Demographics Data Not Available';
  public static DEMOGRAPHICS_HELP: string = 'For questions, please contact ' + DataService.SUPPORT_EMAIL;
  public static DEMOGRAPHICS_LEGEND_NEIGHBOURHOOD: string = "refers to the property's Dissemination Area as defined by Statistics Canada."
  public static DEMOGRAPHICS_LEGEND_COMMUNITY: string = "refers to the property's Forward Sortation Area (FSA), the first 3 digits of the property's postal";
  public static DEMOGRAPHICS_LEGEND_CITY: string = "refers to the property's Census Subdivision as defined by Statistics Canada.";

  public static RATA_NO_RECORDS_SELECTED: string = 'No records selected.'
  public static RATA_NO_REPORT_TYPES_FOUND: string = 'No report types found.';
  public static RATA_NO_REPORT_MODELS_FOUND: string = 'No report models found for the selected report type.';
  public static RATA_NO_REPORT_MODELS_FOUND_PREFIX: string = 'No report models found for ';
  public static RATA_NO_REPORT_PERIODS_FOUND: string = 'No reporting periods found for the selected model name.';
  public static RATA_NO_REPORT_PERIODS_FOUND_PREFIX: string = 'No reporting periods found for ';
  public static RATA_NO_ACTIVITIES_FOUND: string = 'No registration activities found for the report period.';
  public static RATA_MISSING_FILTER_VALUES: string = 'One or more filter dropdown controls are missing values.';
  public static RATA_FILTERED_RECORDS_NOT_FOUND: string = 'No records found that meet the filtering criteria.';
  public static RATA_PAGE_RECORDS_NOT_FOUND: string = 'No records found on the current page.';
  public static RATA_REPORT_DOWNLOAD_IN_PROGRESS: string = 'Preparing report for download. Please wait.';

  public static SSO_DEEPLINK_UNHANDLED_REQUEST: string = 'The single sign-on deeplink request to GeoWarehouse is not supported.';
  public static SSO_DEEPLINK_SIGN_IN: string = 'Signing in...';
  public static SSO_DEEPLINK_USER_PREFERENCES_NOT_LOADED: string = 'SSO user preferences not found.';

  public static CONNECTION_NO_INTERNET: string = 'A GeoWarehouse service is currently unavailable. Please check your internet connection.';

  public static USER_PROFILE_WELCOME_HEADER: string = 'Welcome to GeoWarehouse Online!';
  public static USER_PROFILE_WELCOME_TEXT: string[] = ['Before you begin, please complete the User Profile Section displayed on your screen. This information will allow you to create your own customized GeoWarehouse as well as allow you to create customized reports directly from GeoWarehouse.',
    'Please note that not all fields are mandatory and you may also add or modify this information at any time in the future by accessing "My GeoWarehouse".',
    'The information is collected solely for use in GeoWarehouse in accordance with our Privacy Policy.'];

  public static PAGE_NOT_FOUND_TEXT: string = "Page not found";
  public static PAGE_NOT_FOUND_TEXT_DESCRIPTION: string = "We're sorry, the page you requested could not be found.";

  public static PDF_REPORT_MAIN_PAGE_SUMMARY_FOR_PROPERTY: string = 'Generate a PDF of this GeoWarehouse Property Report. Map Views in the PDF may not appear as they do in your browser and may not be adjusted before generation.';
  public static PDF_REPORT_MAIN_PAGE_SUMMARY_FOR_COMPARABLE_SALES: string = 'Generate a PDF of this GeoWarehouse Comparable Sales Report. Map Views in the PDF may not appear as they do in your browser and may not be adjusted before generation.';
  public static PDF_REPORT_BASIC_REPORT_DESCRIPTION: string = 'A detailed report for clients. Includes only essential information.';
  public static PDF_REPORT_MY_REPORT_DESCRIPTION_FOR_PROPERTY : string = 'Generate a Property Detail Report based on your preference settings.';
  public static PDF_REPORT_MY_REPORT_DESCRIPTION_FOR_COMPARABLE_SALES : string = 'Generate a Comparable Sales Report based on your preferences settings';
  public static PDF_REPORT_CLIENT_REPORT_DESCRIPTION_FOR_PROPERTY : string = 'Suggested report for clients. <b>Excludes</b> Owner Names and Legal Description.';
  public static PDF_REPORT_CLIENT_REPORT_DESCRIPTION_FOR_COMPARABLE_SALES : string = 'Suggested report for clients. <b>Excludes</b> Owner Names and Legal Description.';
  public static PDF_REPORT_MY_REPORT_REPORT_NAME_FOR_PROPERTY : string = 'My Property Report';
  public static PDF_REPORT_MY_REPORT_REPORT_NAME_FOR_COMPARABLE_SALES : string = 'My Report';
  public static PDF_REPORT_CLIENT_REPORT_NAME_FOR_PROPERTY : string = 'Client Report';
  public static PDF_REPORT_CLIENT_REPORT_NAME_FOR_COMPARABLE_SALES : string = 'Client Report';

  public static SESSION_TIMEOUT_MESSAGE = ['Sorry, your session has expired due to inactivity, or has been cancelled because your account has been accessed from another device / browser window.',
    'If you are a Real Estate professional, please log into your respective Board account to restart your session in GeoWarehouse.'];


  public static NEW_VERSION_TITLE = 'New version available';
  public static NEW_VERSION_MESSAGE = ['GeoWarehouse will update to the newest version.',
    'Press OK to continue.'];

  public static TILE_DEFAULT_BUTTON_CAPTION = 'View Details';
  public static TILE_PURCHASE_BUTTON_CAPTION = 'Purchase Now';
  public static TILE_PROD_134_BUTTON_CAPTION = 'Search';
  public static TILE_PROD_133_BUTTON_CAPTION = 'Select Plan';
  public static TILE_REPORT_TOP_UPS_BUTTON_CAPTION = 'Add Now';

  public static COMPARABLE_SEARCH_LOW_BALANCE_HEADER = 'Buy Top-Up?';
  public static COMPARABLE_SEARCH_LOW_BALANCE_MESSAGE = 'Search Comparables Reports balance is low. Please purchase reports top-up to add balance.';
  public static COMPARABLE_SEARCH_NO_BALANCE_MESSAGE = 'Search Comparables Reports have no balance. Please purchase reports top-up to add balance.';
  public static COMPARABLE_SEARCH_LOW_BALANCE_BUY_BUTTON = 'Buy Top-Up';
  public static COMPARABLE_SEARCH_LOW_BALANCE_REMINDER_BUTTON = 'Reminder Later';

  public static COMPARABLE_SEARCH_COUNTER_TOOLTIP: string = "Search comparables count is calculated upon clicking the 'Find Comparables' button.";
  public static COMPARABLE_SEARCH_TOGGLE_TOOLTIP: string = "Search Comparables";

  public static LICENSE_SUBSCRIPTION_EXPIRED: string = 'Your subscription to GeoWarehouse has expired.';
  public static LICENSE_SUBSCRIPTION_INFORM: string = 'Please inform your internal account administrator that the Company Subscription has expired.';
  public static LICENSE_SUBSCRIPTION_PAYMENT: string = 'Once they have submitted payment for the Company, your account will be restored.';
  public static LICENSE_SUBSCRIPTION_RENEW: string = 'To renew your subscription, please click the button below and purchase the "Subscription Renewal" through the store.';

  public static LICENSE_SUBSCRIPTION_RENEWAL_HEADER: string = 'Subscription Renewal Reminder';
  public static LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_SL: string = 'Your annual subscription end date is nearing. Please renew your subscription.';
  public static LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_ML: string = 'Your subscription is nearing its expiration. Please contact your Account Manager to renew your GeoWarehouse Subscription and avoid any service interruption.';

  public static MISSING_SUBJECT_PROPERTY_HEADER = "No Subject Property";
  public static MISSING_SUBJECT_PROPERTY = "Please start with a property search.";

  public static PHASED_IN_ASSESSMENT_DISCLAIMER = 'The Phased-in Assessment reflects the property in the current state and condition and may not be the <br/>' +
    'same value the local municipality used for taxation in the year indicated. <br/>' +
    'Please contact <a target="_blank" class="anchor-white" href="mailTo:propertyline@mpac.ca">propertyline@mpac.ca</a> if you have any questions about the difference <br/>' +
    'between the assessed value, phased-in value, and the value based on the current state and condition.';

  public static CURRENT_ASSESSMENT_DISCLAIMER = 'The Current Assessed Value indicated reflects the current state and condition of the property today, and <br/>' +
    'may not be the same value returned to the local municipality for the current tax year. <br/>' +
    'Please contact <a target="_blank" class="anchor-white" href="mailTo:propertyline@mpac.ca">propertyline@mpac.ca</a> if you have any questions about the difference between the assessed <br/>' +
    'value and the value based on the current state and condition. ';

  public static PROPERTY_REPORT_PRODUCTS_FOR_PURCHASE_LABEL_DESC = 'The following additional reports, available for purchase in the GeoWarehouse Store, provide the insight you need to complete your due diligence.'

  public propertyCodes: any | null;  //TODO: replace with the actual response class

  mapLoadingSpinnerDialogRef: any;

  get someImportantData(): string {
    return this.data;
  }

  set someImportantData(value: string) {
    this.data = value;
  }

  isPin = (identifier: string): boolean => {
    let rgx = /\b\d{9}\b/g;
    let isPin: boolean = rgx.test(identifier);
    return isPin;
  }

  isArn = (identifier: string): boolean => {
    let rgx = /\b\d{15}\b/g;
    let isArn: boolean = rgx.test(identifier);
    return isArn;
  }

  isBlock = (identifier: string): boolean => {
    let rgx = /\b\d{5}\b/g;
    let isBlock: boolean = rgx.test(identifier);
    return isBlock;
  }

  get provinces(): string[] {
    return ['Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland',
      'Nova Scotia',
      'Northwest Territories',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Québec',
      'Saskatchewan',
      'Yukon'];
  }

  get defaultProfilePhotoSource(): string {
    return 'assets/img/default-userprofile-photo.png';
  }

  //ported from gema 2g
  public static addCommas = (amount: string): string => {
    amount += '';
    var x = amount.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  setMapLayerVisibility(mapLayerVisibility: MapLayerVisibility) {
    this.mapLayerVisibility = mapLayerVisibility;
  }

  getMapLayerVisibility() {
    return this.mapLayerVisibility;
  }

  getActiveHeatmapLayerState = (): HeatmapLayerState | undefined => {
    if (this.getMapLayerVisibility().heatMapAverageSales) {
      return new HeatmapLayerState(
        HeatmapBlockTypeEnum.AVERAGE_SALES,
        this.getMapLayerVisibility().heatMapAverageSalesPropertyTypeIsCondo,
        this.getMapLayerVisibility().heatMapAverageSalesDateRangeInMonths
      );

    } else if (this.getMapLayerVisibility().heatMapGrowthRate) {
      return new HeatmapLayerState(
        HeatmapBlockTypeEnum.GROWTH_RATE,
        this.getMapLayerVisibility().heatMapGrowthRatePropertyTypeIsCondo,
        this.getMapLayerVisibility().heatMapGrowthRateDateRangeInMonths
      );

    } else if (this.getMapLayerVisibility().heatMapMarketTurnover) {
      return new HeatmapLayerState(
        HeatmapBlockTypeEnum.MARKET_TURNOVER,
        this.getMapLayerVisibility().heatMapMarketTurnoverPropertyTypeIsCondo,
        this.getMapLayerVisibility().heatMapMarketTurnoverDateRangeInMonths
      );

    }

    return undefined;
  }


  getActiveNhoodHeatmapLayerState = (): HeatmapNhoodLayerState | undefined => {
    if (this.getMapLayerVisibility().heatMapAverageRentalPercentage) {
      return new HeatmapNhoodLayerState(
        HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE
      );

    } else if (this.getMapLayerVisibility().heatMapAverageHouseholdIncome) {
      return new HeatmapNhoodLayerState(
        HeatmapNhoodTypeEnum.AVERAGE_HOUSEHOLD_INCOME
      );
    }
    return undefined;
  }


}
