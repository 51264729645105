import { Component, HostListener, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from "../../../shared/service/user.service";
import { UserPreference } from "../../../core/model/user/preference/user-preference";
import { lastValueFrom, Observable } from "rxjs";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ComponentCanDeactivate } from "../../../core/modified-form/component-can-deactivate";
import { MainMapService } from "../../../home/home/main-map/main-map.service";
import { GenericPreferenceLayerDisplay, SearchMethods } from "./preference-mapping";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { OwnershipType } from "../../../core/model/user/preference/comparable-search-criteria";
import { defaultErrorMatSnackBarConfig, defaultMatSnackBarConfig, lotSizeValues, maxPriceRange, minPriceRange } from "../../../shared/constant/constants";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CurrencyPipe } from "@angular/common";
import { MeasurementUnitService } from 'src/app/shared/service/measurement-unit.service';
import { DataService } from "../../../shared/service/data.service";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { NumberUtility } from "../../../shared/utility/number.utility";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gema3g-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})
export class PreferenceComponent implements OnInit, ComponentCanDeactivate {

  isLoading: boolean = true;
  userPreference: UserPreference;
  selectedSearchMethod: string;
  searchMethods = SearchMethods;
  genericPreferenceLayerDisplay = GenericPreferenceLayerDisplay;
  lros: any[];
  userAccessControl: UserAccessControl;
  minPriceRange = minPriceRange;
  maxPriceRange = maxPriceRange;
  minAreaRange = lotSizeValues.filter(value => {
    return value.id < 15
  });
  maxAreaRange = lotSizeValues.filter(value => {
    return value.id > 1
  });

  preferenceForm: FormGroup;
  saving: boolean = false;
  resetting: boolean = false;
  isSaleAmountNotValid: boolean = false;
  oldMinAmountDisplay: string | null;
  oldMaxAmountDisplay: string | null;
  isSaleAreaNotValid: boolean = false;
  oldMinArea: number | null;
  oldMaxArea: number | null;
  unitOfMeasureToolTip: string = DataService.DISTANCE_COLUMN_IN_COMPARABLE_SALES_REPORT;
  faCircleInfo = faCircleInfo;
  INFINITY: number = DataService.SEARCH_COMPARABLES_INFINITY;
  INFINITY_VALUE_DISPLAY: string = "$" + DataService.SEARCH_COMPARABLES_INFINITY_VALUE_DISPLAY;

  constructor() {
    this.loadPreferences().then(r => {
      this.isLoading = false;
    });
    this.lros = this.mainMapService.getLroPolygonService().getLroKeyValuePairs();
  }

  private userService = inject(UserService);
  private formBuilder = inject(FormBuilder);
  private mainMapService = inject(MainMapService);
  private _snackBar = inject(MatSnackBar);
  private router = inject(Router);
  private currencyPipe = inject(CurrencyPipe);
  private measurementUnitService = inject(MeasurementUnitService);
  private dataService = inject(DataService);
  private loggerService = inject(LoggerService);

  ngOnInit(): void {
    this.userAccessControl = this.userService.getUserAccessControl();
  }

  initPreferenceForm() {
    this.preferenceForm = this.formBuilder.group(
      {
        comparablesPreference: this.formBuilder.group(
          {
            searchCriteria: this.formBuilder.group({
                defaultSearchAreaSelection: ['', []],
                lastDaysDateRange: ['', []],
                lastDaysDateRangeType: ['', []],
                condo: ['', []],
                freehold: ['', []],
                freeholdOrCondoOrAll: [this.userPreference.comparablesPreference.searchCriteria.freeholdOrCondoOrAll, []],
                minAmountDisplay: ['', []],
                maxAmountDisplay: ['', []],
                maxArea: ['', []],
                minArea: ['', []],
              }, {
                validators: [this.saleAmountValidator(), this.saleAreaValidator()]
              }
            )
          }
        ),
        genericPreference: this.formBuilder.group(
          {
            searchMethod: ['', []],
            lro: ['', []],
            uomDistance: ['', []],
            mapType: ['', []],
            layers: this.formBuilder.array(
              this.userPreference.genericPreference.layers.filter(layer => layer.key != "THEMATIC_LAYERS")
                .map(lyr => {
                  this.loggerService.logInfo(lyr.toString());
                  return this.formBuilder.group({
                    key: ['', []],
                    booleanValue: ['', []],
                    value: ['', []],
                  })
                })
            )
          }
        ),
        propertyReportPreference: this.formBuilder.group(
          {
            reportPreferences: this.formBuilder.group({
              pdfClientReport: ['', []],
              pdfCover: ['', []],
              pdfProfileInfo: ['', []],
              pdfPropertyDetails: ['', []],
              pdfStreetView: ['', []],
              pdfAerialMap: ['', []],
              pdfOwnership: ['', []],
              pdfLegalDescription: ['', []],
              pdfAssessmentInformation: ['', []],
              pdfLotSize: ['', []],
              pdfSalesHistory: ['', []],
              pdfDominantMarketGroup: ['', []],
              pdfDemographics: ['', []]
            })
          }
        ),
      }
    );

    this.saleMinAmountControl?.valueChanges.subscribe(frm => {
      this.adjustAmountValue('minAmountDisplay');
    });

    this.saleMaxAmountControl?.valueChanges.subscribe(frm => {
      this.adjustAmountValue('maxAmountDisplay');
    });

  }

  get saleMinAmountControl() {
    return this.preferenceForm.get('comparablesPreference.searchCriteria.minAmountDisplay');
  }

  get saleMaxAmountControl() {
    return this.preferenceForm.get('comparablesPreference.searchCriteria.maxAmountDisplay');
  }

  private adjustAmountValue(amountDisplay: string) {
    const amountControl = this.preferenceForm.get('comparablesPreference.searchCriteria.' + amountDisplay);
    if (amountControl && NumberUtility.isNotNullOrUndefined(amountControl.value)) {
      if(amountControl.value != this.INFINITY) {
        const updatedValue = this.currencyPipe.transform(NumberUtility.removingLeadingZero(amountControl.value), '', 'symbol', '1.0-0');
        amountControl.setValue(updatedValue, {onlySelf: true, emitEvent: false});
      } else {
        amountControl.setValue(this.INFINITY_VALUE_DISPLAY, {onlySelf: true, emitEvent: false});
      }
    }
  }

  saleAmountValidator(): ValidatorFn | ValidationErrors {
    return (group: FormGroup): ValidationErrors | null => {
      const minAmountControl = group.controls['minAmountDisplay'];
      const minAmount = Number(minAmountControl?.value?.toString().replace(/\D/g, ''));
      const maxAmountControl = group.controls['maxAmountDisplay'];
      const maxAmount = Number(maxAmountControl?.value?.toString().replace(/\D/g, ''))
      if (minAmount > maxAmount){
         minAmountControl.setErrors({invalidSalePrice: true});
         maxAmountControl.setErrors({invalidSalePrice: true});
         setTimeout(() => {
           minAmountControl?.setValue(this.oldMinAmountDisplay, {onlySelf: true, emitEvent: true});
           maxAmountControl?.setValue(this.oldMaxAmountDisplay, {onlySelf: true, emitEvent: true});
           minAmountControl.setErrors(null);
           maxAmountControl.setErrors(null);
         }, 1500);

         this.setIsSaleAmountNotValid();
      } else {
        this.oldMinAmountDisplay = minAmount.toString();
        this.oldMaxAmountDisplay = maxAmount.toString();
        minAmountControl.setErrors(null);
        maxAmountControl.setErrors(null);
      }
      return null;
    };
  }

  setIsSaleAmountNotValid() {
    this.isSaleAmountNotValid = true;
    setTimeout(() => {
      this.isSaleAmountNotValid = false;
    }, 1500);
  }


  get saleMinAreaControl() {
    return this.preferenceForm.get('comparablesPreference.searchCriteria.minArea');
  }

  get saleMaxAreaControl() {
    return this.preferenceForm.get('comparablesPreference.searchCriteria.maxArea');
  }

  saleAreaValidator(): ValidatorFn | ValidationErrors {
    return (group: FormGroup): ValidationErrors | null => {
      const minAreaControl = group.controls['minArea'];
      const minArea = minAreaControl?.value;
      const maxAreaControl = group.controls['maxArea'];
      const maxArea = maxAreaControl?.value;
      if (minArea > maxArea){
        minAreaControl.setErrors({invalidSaleArea: true});
        maxAreaControl.setErrors({invalidSaleArea: true});
        setTimeout(() => {
          minAreaControl?.setValue(this.oldMinArea, {onlySelf: true, emitEvent: true});
          maxAreaControl?.setValue(this.oldMaxArea, {onlySelf: true, emitEvent: true});
          minAreaControl.setErrors(null);
          maxAreaControl.setErrors(null);
        }, 1500);

        this.setIsSaleAreaNotValid();
      } else {
        this.oldMinArea = minArea;
        this.oldMaxArea = maxArea;
        minAreaControl.setErrors(null);
        maxAreaControl.setErrors(null);
      }
      return null;
    };
  }

  setIsSaleAreaNotValid() {
    this.isSaleAreaNotValid = true;
    setTimeout(() => {
      this.isSaleAreaNotValid = false;
    }, 1500);
  }

  getGenericPreference() {
    return this.preferenceForm.controls['genericPreference'] as AbstractControl<any>;
  }

  get genericPreferenceLayers() {
    //this.loggerService.logInfo(this.preferenceForm.get('genericPreference.genericPreferenceLayers'));
    return this.preferenceForm.get('genericPreference.layers') as FormArray;
  }


  async loadPreferences() {
    this.userPreference = await lastValueFrom(this.userService.getPreferences());
    this.loggerService.logInfo(this.userPreference);
    this.initPreferenceForm();
    this.preferenceForm.patchValue(this.userPreference);
    this.loggerService.logInfo(this.preferenceForm.getRawValue());
  }

  async loadDefaultPreferences() {
    this.resetting = true;
    try {
      const defaultUserPreferences = await lastValueFrom(this.userService.getDefaultPreferences());
      defaultUserPreferences.makeAdjustmentsOnSystemDefaultValues();
      this.preferenceForm.reset(defaultUserPreferences);
      this.preferenceForm.markAsDirty();
    } finally {
      this.resetting = false;
    }
  }

  get saveButtonDisabled(): boolean {
    return !this.preferenceForm?.valid || !this.preferenceForm?.dirty || this.saving || this.resetting;
  }

  async savePreferences() {
    if (this.preferenceForm?.valid && this.preferenceForm?.dirty) {
      try {
        this.openSnackBarSaving();
        this.saving = true;
        this.loggerService.logInfo(this.preferenceForm.getRawValue());
        this.userPreference.patchFromUserInput(<UserPreference>this.preferenceForm.getRawValue());
        this.loggerService.logInfo(this.userPreference);
        const saved = await lastValueFrom(this.userService.saveUserPreferences(this.userPreference));
        if (!saved) {
          this.openSnackBarError();
        } else {
          this.mainMapService.updateMainMapType(this.userPreference);
          this.measurementUnitService.setUnitOfMeasure(this.userPreference);
          this.preferenceForm.markAsPristine();
          this._snackBar.dismiss();
          await this.router.navigate(['../home']);
        }
      } catch (ex) {
        this.loggerService.logError(ex);
        this.openSnackBarError();
      } finally {
        this.saving = false;
      }
    }
  }

  openSnackBarSaving() {
    const msg = "Saving preferences. Please wait.";
    this._snackBar.open(msg, 'Close', defaultMatSnackBarConfig);
  }

  openSnackBarError() {
    const msg = "There was an error with your submission, please try again.";
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.preferenceForm?.dirty;
  }

  getGenericPreferenceLayerDisplayLabel(key: string, isLabel: boolean) {
    let display = this.genericPreferenceLayerDisplay.find(value => {
      return value.key == key
    });
    if (display) {
      if (isLabel) {
        return display.label;
      }
      return display.description
    }
    return '';
  }

  get lastDaysDateRange() {
    return this.preferenceForm.get('comparablesPreference.searchCriteria.lastDaysDateRange')?.value;
  }

  setLastDaysDateRange(val: string | null) {
    this.preferenceForm.get('comparablesPreference.searchCriteria.lastDaysDateRange')?.setValue(val);
  }

  setOwnershipType(val: OwnershipType) {
    if (val == 'ALL') {
      this.preferenceForm.get('comparablesPreference.searchCriteria.condo')?.setValue(true);
      this.preferenceForm.get('comparablesPreference.searchCriteria.freehold')?.setValue(true);
    } else if (val == 'CONDO') {
      this.preferenceForm.get('comparablesPreference.searchCriteria.condo')?.setValue(true);
      this.preferenceForm.get('comparablesPreference.searchCriteria.freehold')?.setValue(null);
    } else if (val == 'FREEHOLD') {
      this.preferenceForm.get('comparablesPreference.searchCriteria.condo')?.setValue(null);
      this.preferenceForm.get('comparablesPreference.searchCriteria.freehold')?.setValue(true);
    }

  }

  isImperial(): boolean {
    return this.preferenceForm.get('genericPreference.uomDistance')?.value == 'IMPERIAL';
  }


}
