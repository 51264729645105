import {Preference} from "./preference";

export class ReportPreference {
  constructor(reportPreferences: ReportPreference) {
    if (reportPreferences) {
      this.pdfPreferences = [];
      this.pdfPreferences.push(...reportPreferences.pdfPreferences);

      this.webPreferences = [];
      this.webPreferences.push(...reportPreferences.webPreferences);

      this.initializeFromPdfArray();
    }
  }

  pdfPreferences: Preference[];
  webPreferences: Preference[]; // it looks like this was a feature that got disabled

  pdfCover: boolean = false;
  pdfPropertyDetails: boolean = false;
  pdfStreetView: boolean = false;
  pdfAerialMap: boolean = false;
  pdfDemographics: boolean = false;
  pdfOwnership: boolean = false;
  pdfLegalDescription: boolean = false;
  pdfAssessmentInformation: boolean = false;
  pdfDominantMarketGroup: boolean = false;
  pdfSalesHistory: boolean = false;
  pdfClientReport = "Client Report";
  pdfLotSize: boolean = false;
  pdfProfileInfo: boolean = false;

  initializeFromPdfArray() {

    this.pdfPreferences.forEach(pref => {

      if (pref.key === 'COVER' && pref.value === 'true') {
        this.pdfCover = true;
      }
      if (pref.key === 'PROPERTY_DETAILS' && pref.value === 'true') {
        this.pdfPropertyDetails = true;
      }
      if (pref.key === 'STREET_VIEW' && pref.value === 'true') {
        this.pdfStreetView = true;
      }
      if (pref.key === 'AERIAL_VIEW' && pref.value === 'true') {
        this.pdfAerialMap = true;
      }
      if (pref.key === 'DEMOGRAPHICS' && pref.value === 'true') {
        this.pdfDemographics = true;
      }
      if (pref.key === 'OWNERSHIP' && pref.value === 'true') {
        this.pdfOwnership = true;
      }
      if (pref.key === 'LEGAL_DESCRIPTION' && pref.value === 'true') {
        this.pdfLegalDescription = true;
      }
      if (pref.key === 'ASSESSMENT_INFO' && pref.value === 'true') {
        this.pdfAssessmentInformation = true;
      }
      if (pref.key === 'DOMINAT_MARKET_GROUP' && pref.value === 'true') {
        this.pdfDominantMarketGroup = true;
      }
      if (pref.key === 'SALES_HISTORY' && pref.value === 'true') {
        this.pdfSalesHistory = true;
      }
      if (pref.key === 'REPORT_TITLE' && pref.value != 'Client Report') {
        this.pdfClientReport = pref.value;
      }
      if (pref.key === 'LOT_SIZE' && pref.value === 'true') {
        this.pdfLotSize = true;
      }
      if (pref.key === 'PROFILE_INFO' && pref.value === 'true') {
        this.pdfProfileInfo = true;
      }
    });

  }

  setPdfArrayItem(key: string, value: string | boolean) {
    const existingPreference = this.pdfPreferences.find(pref => pref.key == key);
    if (existingPreference) {
      existingPreference.value = value.toString();
    } else {
      this.pdfPreferences.push(new Preference(key, value.toString()));
    }
  }

  populatePdfArray(reportPreferences: ReportPreference) {
    this.setPdfArrayItem('COVER', reportPreferences.pdfCover);
    this.setPdfArrayItem('PROPERTY_DETAILS', reportPreferences.pdfPropertyDetails);
    this.setPdfArrayItem('STREET_VIEW', reportPreferences.pdfStreetView);
    this.setPdfArrayItem('AERIAL_VIEW', reportPreferences.pdfAerialMap);
    this.setPdfArrayItem('DEMOGRAPHICS', reportPreferences.pdfDemographics);
    this.setPdfArrayItem('OWNERSHIP', reportPreferences.pdfOwnership);
    this.setPdfArrayItem('LEGAL_DESCRIPTION', reportPreferences.pdfLegalDescription);
    this.setPdfArrayItem('ASSESSMENT_INFO', reportPreferences.pdfAssessmentInformation);
    this.setPdfArrayItem('DOMINAT_MARKET_GROUP', reportPreferences.pdfDominantMarketGroup);
    this.setPdfArrayItem('SALES_HISTORY', reportPreferences.pdfSalesHistory);
    this.setPdfArrayItem('REPORT_TITLE', reportPreferences.pdfClientReport)
    this.setPdfArrayItem('LOT_SIZE', reportPreferences.pdfLotSize);
    this.setPdfArrayItem('PROFILE_INFO', reportPreferences.pdfProfileInfo);
  }

  patchFromUserInput(reportPreferences: ReportPreference) {
    if(reportPreferences){
      this.populatePdfArray(reportPreferences);
    }

  }

  makeAdjustmentsOnSystemDefaultValues() {

  }
}
