<div class="generate-pdf-my-report">
  <h1 id="pdf-h1-client-title" class="generate-pdf-my-report-header">
    Customize your client report
    <a id="pdf-link-client-close" (click)="closeAdditionalParams()" class="pull-right">
      <gema3g-svg-icon [icon]="'close'" [width]="16" [height]="16" fill="#cccccc"></gema3g-svg-icon>
    </a>
  </h1>
  <div>
    <div class="report-format">
      <div class="report-format-column col-span-2">
        <div class="report-format-column-title">
          <div>
            <mat-label id="pdf-label-client-rt">Report Title</mat-label>
          </div>
          <div>
            <input id="pdf-tf-client-rt" [(ngModel)]="inputParam.reportTitle" mat-input placeholder="Report title" type="text"/>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="!my-2"></mat-divider>
    <div class="report-format">
      <div class="report-format-column col-span-3">
        <div class="report-format-column-row">
          <div id="pdf-client-cover-label" class="report-format-column-row-item">
            Cover
          </div>
          <div class="report-format-column-row-item">
            <mat-slide-toggle id="pdf-toggle-client-cover" [(ngModel)]="inputParam.cover" color="primary"></mat-slide-toggle>
          </div>

          <div id="pdf-client-realtor-label" class="report-format-column-row-item">
            Profile Information
          </div>
          <div class="report-format-column-row-item">
            <mat-slide-toggle id="pdf-toggle-client-realtor" [(ngModel)]="inputParam.profileInfo" color="primary"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>

    <mat-divider class="!my-2"></mat-divider>

    <mat-accordion multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="pdf-report-mat-panel-title" >
            Property Information
          </mat-panel-title>
        </mat-expansion-panel-header>
        <gema3g-generate-pdf-report-property-info-section
          [inputParam]="inputParam"
          (openComparableSales)="goToComparableSales()"
          (reloadProperty)="goReloadProperty($event)"
        >
        </gema3g-generate-pdf-report-property-info-section>
        <mat-divider class="!my-2"></mat-divider>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="pdf-report-mat-panel-title">
            Area Sales Information
          </mat-panel-title>
        </mat-expansion-panel-header>
        <gema3g-generate-pdf-report-area-sales-info-section
          [inputParam]="inputParam"
          (openComparableSales)="goToComparableSales()"
        >
        </gema3g-generate-pdf-report-area-sales-info-section>
        <mat-divider class="!my-2"></mat-divider>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="inputParam.marketAnalytics.canDisplayMarketAnalytics">
        <mat-expansion-panel-header>
          <mat-panel-title class="pdf-report-mat-panel-title">
            Market Analytics
          </mat-panel-title>
        </mat-expansion-panel-header>
        <gema3g-generate-pdf-report-market-analytics-section
          [inputParam]="inputParam"

        >
        </gema3g-generate-pdf-report-market-analytics-section>
        <mat-divider class="!my-2"></mat-divider>
      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>
